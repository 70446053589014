<template>
  <div class="my-0.5" :id="item._id">
    <div class="grid grid-cols-2 rounded-lg pl-2 pr-4">
      <div
        :class="`flex items-center col-span-1 py-1 pl-2 pr-4 rounded-l-lg ${coverageColorClass}`"
        :style="`margin-left: ${depth * 8}px`"
      >
        <div v-if="'children' in item" class="mt-2">
          <button @click="toggleIsOpen">
            <ChevronDownIcon v-if="isOpen" class="h-6 w-6 text-gray-800" aria-hidden="true" />
            <ChevronRightIcon v-else class="h-6 w-6 text-gray-800" aria-hidden="true" />
          </button>
        </div>
        <div v-else class="px-3"></div>
        <div class="p-2 text-xs">
          {{ item.name
          }}<span class="text-xs text-gray-500 ml-2" style="font-size: 9px">{{
            item.source_id
          }}</span>
        </div>
        <div class="flex-grow"></div>
        <div class="flex-row justify-end items-center text-xs text-gray-700 flex">
          <div class="pr-2">
            {{ `${amountCovered}/${amountPossibilities}` }}
          </div>
          <Menu as="div" class="relative text-left">
            <div class="pt-1">
              <MenuButton class="group relative -mr-1 h-5 w-5 text-gray-800 rounded-sm">
                <EllipsisVerticalIcon aria-hidden="true" />
                <span class="absolute -inset-1" />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-700 ring-opacity-5"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }" @click="$emit('toggleTrackingEnabled', item)">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-3 py-2',
                      ]"
                    >
                      <template v-if="item.tracking_enabled">
                        <XMarkIcon
                          class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Disable Tracking
                      </template>
                      <template v-else>
                        <CheckIcon
                          class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Enable Tracking
                      </template>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div :class="`col-span-1 py-1 rounded-r-lg pr-2 ${coverageColorClass}`">
        <button
          class="p-2 border border-dashed border-gray-800 rounded-md w-full focus:border-solid focus:outline-none focus:bg-[#00000008]"
          style="min-height: 35px"
          v-if="item.tracking_enabled"
          @mouseover="($event?.target as HTMLElement)?.focus()"
          @mouseleave="
            ($event?.target as HTMLElement)?.blur();
            resetValidationError();
          "
          @keydown="handleTagPaste($event)"
          type="button"
        >
          <draggable
            class="list-group flex flex-wrap gap-1"
            :list="mappingTable[item.source_id]"
            :itemKey="item.source_id"
            :componentData="{ props: { item } }"
            :group="'hierarchy'"
            ghost-class="ghost"
            @change="$emit('postProcessTagMove', { event: $event, item: item })"
            @end="resetValidationError!"
            :move="validateTagMove"
            draggable=".canMove"
          >
            <template #item="{ element }">
              <div
                :class="[
                  !isInherited(item, element) ? 'canMove' : 'blockMove',
                  `inline-flex items-center gap-x-1.5 rounded-md bg-${getTagColor(
                    element.type,
                  )}-100 px-2 py-1 text-xs font-medium text-${getTagColor(
                    element.type,
                  )}-700 list-group-item`,
                ]"
              >
                {{ isInherited(item, element) ? element.number : formatTagText(element) }}
                <button
                  v-if="!isInherited(item, element)"
                  type="button"
                  class="group relative h-3.5 w-3.5 rounded-sm"
                  @click="$emit('removeTagInHierarchy', { node: item, tag: element })"
                >
                  <XMarkIcon class="h-3.5 w-3.5" aria-hidden="true" />
                  <span class="absolute -inset-1" />
                </button>
              </div>
            </template>
          </draggable>
        </button>
        <div v-else class="flex justify-center mt-3 uppercase text-gray-800" style="font-size: 8px">
          Tracking Disabled
        </div>
      </div>
    </div>
    <div v-show="isOpen" v-if="'children' in item">
      <HierarchyPlanItem
        v-for="(child, index) in item.children"
        :item="child"
        :mappingTable="mappingTable"
        :sectionMaskPossibilityMap="sectionMaskPossibilityMap"
        :sectionMaskSet="sectionMaskSet"
        :isInherited="isInherited"
        :key="index"
        :copiedTag="copiedTag"
        :getTagColor="getTagColor"
        :formatTagText="formatTagText"
        :validateTagMove="validateTagMove"
        :resetValidationError="resetValidationError"
        :depth="depth + 1"
        @removeTagInHierarchy="$emit('removeTagInHierarchy', $event)"
        @postProcessTagMove="$emit('postProcessTagMove', $event)"
        @toggleTrackingEnabled="$emit('toggleTrackingEnabled', $event)"
      ></HierarchyPlanItem>
    </div>
  </div>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { XMarkIcon, EllipsisVerticalIcon, CheckIcon } from "@heroicons/vue/24/outline";
import { PlannerItemWithChildren } from "oai-services";
import { defineComponent, PropType } from "vue";
import draggable from "vuedraggable";
import { HierarchyTag } from "@/types/HierarchyTag";

export default defineComponent({
  name: "HierarchyPlanItem",
  props: {
    item: {
      type: Object as PropType<PlannerItemWithChildren>,
      required: true,
    },
    mappingTable: {
      type: Object as PropType<Record<string, HierarchyTag[]>>,
      required: true,
    },
    sectionMaskPossibilityMap: {
      type: Object as PropType<Record<string, Set<string>>>,
      required: true,
    },
    sectionMaskSet: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
    isInherited: {
      type: Function,
      required: true,
    },
    getTagColor: {
      type: Function,
      required: true,
    },
    formatTagText: {
      type: Function,
      required: true,
    },
    validateTagMove: {
      type: Function,
      required: true,
    },
    resetValidationError: {
      type: Function,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    copiedTag: {
      type: Object as PropType<HierarchyTag>,
      required: false,
    },
  },
  data: function () {
    return {
      isOpen: true,
    };
  },
  emits: ["removeTagInHierarchy", "postProcessTagMove", "validateTagMove", "toggleTrackingEnabled"],
  components: {
    XMarkIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    EllipsisVerticalIcon,
    draggable,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  mounted() {
    if (!this.item.tracking_enabled) {
      this.isOpen = false;
    }
  },
  computed: {
    coverageColorClass() {
      return this.amountPossibilities === 0
        ? "bg-gray-100"
        : this.amountPossibilities === this.amountCovered
        ? "bg-green-50/30"
        : "bg-yellow-100";
    },
    amountPossibilities() {
      return this.item.source_id in this.sectionMaskPossibilityMap
        ? this.sectionMaskPossibilityMap[this.item.source_id].size
        : 0;
    },
    amountCovered() {
      return this.item.source_id in this.sectionMaskPossibilityMap
        ? [...this.sectionMaskSet].filter((mask) =>
            this.sectionMaskPossibilityMap[this.item.source_id].has(mask),
          ).length
        : 0;
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    handleTagPaste(e: KeyboardEvent) {
      const syntheticMoveEvent = {
        draggedContext: {
          element: this.copiedTag,
        },
        relatedContext: {
          component: {
            componentData: {
              props: {
                item: this.item,
              },
            },
          },
          list: this.mappingTable[this.item.source_id],
        },
        from: true,
        to: false,
      };

      if ((e.ctrlKey || e.metaKey) && e.code === "KeyV" && this.copiedTag) {
        const result = this.validateTagMove(syntheticMoveEvent);

        if (result === false) {
          return;
        }

        this.$emit("postProcessTagMove", {
          event: {
            added: {
              element: this.copiedTag,
              newIndex: 0,
            },
          },
          item: this.item,
        });
      }
    },
  },
});
</script>

<style scoped>
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}

.blockMove {
  cursor: default;
}
</style>
