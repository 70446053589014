<template>
  <div class="flex flex-col gap-1">
    <div class="flex gap-1">
      <input
        type="checkbox"
        :checked="selectedChangeIds.has(change._id)"
        @click="toggleChange(change._id)"
      />
      <div class="flex gap-3 items-center">
        <span @click="toggleChange(change._id)" class="cursor-pointer">{{
          change.plannerItem.name
        }}</span>
        <span v-if="selectedChangeIds.has(change._id)">
          <a
            class="text-blue-700 hover:text-blue-800 cursor-pointer"
            @click="showMergeOptions = true"
            v-if="!showMergeOptions && mergeItems.length > 0"
            >merge</a
          >
          <span v-if="showMergeOptions" class="flex gap-3 items-center">
            <span>⇨</span>
            <select
              :value="merges[change._id]"
              @change="handleMergeSelectChange"
              class="border px-1 py-0.5 text-sm"
              style="height: 24px"
            >
              <option value="">&lt;None&gt;</option>
              <option v-for="item in mergeItems" :key="item.value" :value="item.value">
                {{ item.name }}
              </option>
            </select>
          </span>
        </span>
      </div>
    </div>
    <div v-for="childChange in change.children" :key="childChange._id" class="ml-5">
      <ImportPlanPlannerItemWithMerge
        :selectedChangeIds="selectedChangeIds"
        :change="childChange"
        @setChange="$emit('setChange', $event)"
        :deletedPlannerItems="deletedPlannerItems"
        :merges="merges"
        @setMerge="$emit('setMerge', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PlannerItem } from "oai-services";
import { defineComponent, PropType } from "vue";
import { Change, HierarchicalChange } from "@/views/planner/components/import/ImportPlanMixins";

export default defineComponent({
  name: "ImportPlanPlannerItemWithMerge",
  props: {
    selectedChangeIds: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
    change: {
      type: Object as PropType<HierarchicalChange<PlannerItem | null>>,
      required: true,
    },
    deletedPlannerItems: {
      type: Array as PropType<Change[]>,
      required: true,
    },
    merges: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  data: () => ({
    showMergeOptions: false,
  }),
  emits: ["setChange", "setMerge"],
  computed: {
    mergeItems() {
      const mergedToPlannerItemIds = new Set(Object.values(this.merges));
      const currentMergedToPlannerItemId = this.merges[this.change._id];
      return this.deletedPlannerItems
        .filter(
          ({ _id, plannerItem }) =>
            !this.selectedChangeIds.has(_id) &&
            (!mergedToPlannerItemIds.has(plannerItem._id) ||
              currentMergedToPlannerItemId === plannerItem._id),
        )
        .map(({ plannerItem }) => ({
          value: plannerItem._id,
          name: plannerItem.name,
        }));
    },
  },
  methods: {
    toggleChange(changeId: string) {
      this.$emit("setChange", { changeId, selected: !this.selectedChangeIds.has(changeId) });
    },
    handleMergeSelectChange(event: Event) {
      this.$emit("setMerge", {
        changeId: this.change._id,
        to: (event.target as HTMLSelectElement).value,
      });
    },
  },
});
</script>
