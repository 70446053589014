import { useQuery } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "oai-services";
import { Stream } from "oai-services";
import { useHasPermission } from "oai-services";
import { computed } from "vue";
import StreamRepository from "@/repositories/StreamRepository";
import logger from "@/services/logger";

export const useStreams = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasPermission = useHasPermission("live_camera_feature_user");

  const { data, isLoading, error } = useQuery<Stream[]>({
    queryKey: ["streams", currentCustomerName, currentSiteId],
    queryFn: async () => {
      try {
        if (hasPermission) {
          return await StreamRepository.loadStreams(currentCustomerName, currentSiteId);
        } else {
          return [];
        }
      } catch (error) {
        // Handle error within the query function
        logger.error(error);
        throw error; // Rethrow the error to be caught by Vue Query
      }
    },
  });

  const streams = computed(() => data.value || []);

  return { streams, isLoading, error };
};
