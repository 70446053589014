import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { Stream } from "oai-services";
import { apiClient } from "@/repositories/clients";
import { FullStreamRebootResult, StreamRebootResult } from "@/types/Stream";

const mapStream = (stream: Stream<string>): Stream => ({
  ...stream,
  status_updated_at: stream.status_updated_at ? parseISO(stream.status_updated_at) : null,
});

const mapStreamRebootResult = (
  streamRebootResult: FullStreamRebootResult<string>,
): FullStreamRebootResult => ({
  ...streamRebootResult,
  created: parseISO(streamRebootResult.created),
});

const loadAllStreams = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>("/stream/all")
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadActiveStreams = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>("/stream/active")
    .then((response) => response.data.map((stream) => mapStream(stream)));

const loadStreams = (customerName: string, siteId: string): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>(`/stream/${customerName}/${siteId}`)
    .then((response) => response.data.map((stream) => mapStream(stream)));

const rebootStream = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<StreamRebootResult> =>
  apiClient
    .post<void, AxiosResponse<StreamRebootResult>>(
      `/stream/${customerName}/${siteId}/${cameraId}/reboot`,
      undefined,
      { timeout: 100000 },
    )
    .then((response) => response.data);

const setStatus = (
  customerName: string,
  siteId: string,
  cameraId: string,
  status: Required<Stream["status"]>,
): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}/status/${status}`,
    )
    .then((response) => mapStream(response.data));

const loadLatestRebootResults = (): Promise<FullStreamRebootResult[]> =>
  apiClient
    .get<FullStreamRebootResult<string>[]>(`/stream/reboot-result/latest`)
    .then((response) =>
      response.data.map((streamRebootResult) => mapStreamRebootResult(streamRebootResult)),
    );

const loadOfflineStreamCount = (): Promise<number> =>
  apiClient.get<{ count: number }>(`/stream/offline/count`).then((response) => response.data.count);

const deleteStream = (customerName: string, siteId: string, cameraId: string): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(`/stream/${customerName}/${siteId}/${cameraId}`)
    .then((response) => response.data);

const createStream = (
  streamToCreate: Omit<
    Stream,
    "_id" | "thumbnail_url" | "created" | "created_by" | "updated" | "updated_by"
  >,
): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(`/stream/`, streamToCreate)
    .then((response) => mapStream(response.data));

type StreamToUpdate = Partial<
  Pick<
    Stream,
    | "name"
    | "rtp"
    | "aws_stream_id"
    | "resolution_width"
    | "resolution_height"
    | "fps"
    | "image_start"
    | "image_end"
    | "image_weekdays"
    | "archive_duration"
  >
>;

const updateStream = (
  customerName: string,
  siteId: string,
  cameraId: string,
  streamToUpdate: StreamToUpdate,
): Promise<Stream> =>
  apiClient
    .patch<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}`,
      streamToUpdate,
    )
    .then((response) => mapStream(response.data));

const detachCamera = (customerName: string, siteId: string, cameraId: string): Promise<Stream> =>
  apiClient
    .post<void, AxiosResponse<Stream<string>>>(
      `/stream/${customerName}/${siteId}/${cameraId}/detach`,
    )
    .then((response) => mapStream(response.data));

const loadAllStreamsWithDeprecated = (): Promise<Stream[]> =>
  apiClient
    .get<Stream<string>[]>(`/stream/all/with-deprecated`)
    .then((response) => response.data.map((stream) => mapStream(stream)));

export default {
  loadAllStreams,
  loadActiveStreams,
  loadStreams,
  rebootStream,
  loadLatestRebootResults,
  loadOfflineStreamCount,
  deleteStream,
  createStream,
  updateStream,
  detachCamera,
  setStatus,
  loadAllStreamsWithDeprecated,
};
