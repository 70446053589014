import { ActualEvent, Plan, PlanConfig, PlannedEvent, PlannerItem } from "oai-services";
import { v4 as uuidv4 } from "uuid";

type PlannerItemMap = Record<string, PlannerItem | undefined>;

type PlannerItemMaps = {
  originalPlannerItemsById: PlannerItemMap;
  plannerItemsById: PlannerItemMap;
  originalPlannerItemsBySourceId: PlannerItemMap;
  plannerItemsBySourceId: PlannerItemMap;
  originalPlannedEventsBySourceId: Record<string, PlannedEvent | undefined>;
  plannedEventsBySourceId: Record<string, PlannedEvent | undefined>;
  originalPlannedEventsByPlannerItemId: Record<string, PlannedEvent | undefined>;
  originalPlannedEventsById: Record<string, PlannedEvent | undefined>;
  plannedEventsById: Record<string, PlannedEvent | undefined>;
  plannedEventsByPlannerItemId: Record<string, PlannedEvent | undefined>;
  actualEventsBySourceId: Record<string, ActualEvent | undefined>;
};

type ChangesMaps = {
  plannerItemChangesById: Record<string, Change<PlannerItem> | undefined>;
  deletedPlannerItemChangesById: Record<string, Change | undefined>;
  addedPlannerItemChangesById: Record<string, Change | undefined>;
  addedPlannerItemChangesByChangeId: Record<string, Change | undefined>;
  parentPlannerItemChangesById: Record<string, Change | undefined>;
  connectedDependenciesByFromId: Record<string, Change<PlannerItem>>;
  disconnectedDependenciesByFromId: Record<string, Change<PlannerItem>>;
};

export type Change<TEntity = PlannerItem | null> = {
  _id: string;
  plannerItem: PlannerItem;
  from: TEntity;
  to: TEntity;
  hidden?: boolean;
};

export type HierarchicalChange<TEntity> = Change<TEntity> & {
  children: HierarchicalChange<TEntity>[];
};

export type Changes = {
  updatedPlan: Omit<Change<Plan>, "plannerItem"> | null;
  updatedPlannedEvents: Change<PlannedEvent>[];
  updatedPlannerItems: Change<PlannerItem>[];
  updatedParentPlannerItems: Change[];
  deletedPlannerItems: Change[];
  addedPlannerItems: Change[];
  connectedPlannedEvents: Change<PlannerItem>[];
  disconnectedPlannedEvents: Change<PlannerItem>[];
};

export default {
  methods: {
    getPlanChanges(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
    ): Omit<Change<Plan>, "plannerItem"> | null {
      if (originalPlanConfig.plan.name !== planConfig.plan.name) {
        return {
          _id: `updatedPlan_${originalPlanConfig.plan._id}`,
          from: originalPlanConfig.plan,
          to: planConfig.plan,
        };
      }
      return null;
    },
    getPlannedEventChanges(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      plannerItemMaps: PlannerItemMaps,
      changes: Pick<
        Changes,
        | "updatedPlannerItems"
        | "deletedPlannerItems"
        | "addedPlannerItems"
        | "updatedParentPlannerItems"
      >,
      selectedChangeIds: Set<string> | null,
      merges: Record<string, string>,
      changesMaps: ChangesMaps,
    ): Change<PlannedEvent>[] {
      const { originalPlannerItemsById, plannedEventsBySourceId } = plannerItemMaps;
      // leaf calculation is based on the final merged planner items,
      // so the added planner items and the ones where the parent changed are also considered
      const mergedPlannerItems = this.getMergedPlannerItems(
        originalPlanConfig,
        planConfig,
        originalPlanConfig.plan,
        changes,
        plannerItemMaps,
        selectedChangeIds,
        merges,
        changesMaps,
      );
      const mergedParentPlannerItemIds = mergedPlannerItems
        .filter(({ parent_id: parentId }) => parentId)
        .reduce((acc, plannerItem) => {
          acc.add(plannerItem.parent_id as string);
          return acc;
        }, new Set<string>());

      return originalPlanConfig.planned_events
        .filter(
          (originalPlannedEvent) =>
            !mergedParentPlannerItemIds.has(originalPlannedEvent.planner_item_id),
        )
        .map((originalPlannedEvent) => {
          const originalPlannerItem =
            originalPlannerItemsById[originalPlannedEvent.planner_item_id];
          if (!originalPlannerItem) {
            throw this.createError(
              "Missing original planner item for original planned event",
              originalPlannedEvent,
            );
          }
          const updatedPlannedEvent = plannedEventsBySourceId[originalPlannerItem.source_id];
          return {
            _id: `updatedPlannedEvent_${originalPlannedEvent._id}`,
            plannerItem: originalPlannerItem,
            from: originalPlannedEvent,
            to: updatedPlannedEvent,
          };
        })
        .filter(
          ({ from, to }) =>
            to &&
            (from.start.getTime() !== to.start.getTime() ||
              from.end.getTime() !== to.end.getTime()),
        ) as Change<PlannedEvent>[];
    },
    getUpdatedPlannerItem(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      { plannerItemsBySourceId }: PlannerItemMaps,
    ): Change<PlannerItem>[] {
      return originalPlanConfig.planner_items
        .map((originalPlannerItem) => {
          const plannerItem = plannerItemsBySourceId[originalPlannerItem.source_id];
          return {
            _id: `updatedPlannerItem_${originalPlannerItem._id}`,
            plannerItem: originalPlannerItem,
            from: originalPlannerItem,
            to: plannerItem,
          };
        })
        .filter(
          (change) => change.to && change.from.name !== change.to.name,
        ) as Change<PlannerItem>[];
    },
    getDeletedPlannerItems(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      { plannerItemsBySourceId }: PlannerItemMaps,
      selectedChangeIds: Set<string> | null,
      merges: Record<string, string>,
    ): Change[] {
      const mergesByTo = Object.entries(merges).reduce((acc, [changeId, to]) => {
        acc[to] = changeId;
        return acc;
      }, {} as Record<string, string>);
      return originalPlanConfig.planner_items
        .filter((originalPlannerItem) => !plannerItemsBySourceId[originalPlannerItem.source_id])
        .map((originalPlannerItem) => {
          const mergedToChangeId = mergesByTo[originalPlannerItem._id];
          return {
            _id: `deletedPlannerItem_${originalPlannerItem._id}`,
            plannerItem: originalPlannerItem,
            from: originalPlannerItem,
            to: null,
            hidden:
              !!mergedToChangeId && !!selectedChangeIds && selectedChangeIds.has(mergedToChangeId),
          };
        });
    },
    getAddedPlannerItems(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      { originalPlannerItemsBySourceId }: PlannerItemMaps,
    ): Change[] {
      return planConfig.planner_items
        .filter((plannerItem) => !originalPlannerItemsBySourceId[plannerItem.source_id])
        .map((plannerItem) => ({
          _id: `addedPlannerItem_${plannerItem._id}`,
          plannerItem,
          from: null,
          to: plannerItem,
        }));
    },
    getUpdatedParentPlannerItem(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      { originalPlannerItemsById, plannerItemsBySourceId, plannerItemsById }: PlannerItemMaps,
      { addedPlannerItems }: Pick<Changes, "addedPlannerItems">,
      selectedChangeIds: Set<string> | null,
    ): Change[] {
      const addedPlannerItemChangesById = addedPlannerItems.reduce((acc, change) => {
        acc[change.plannerItem._id] = change;
        return acc;
      }, {} as Record<string, Change | undefined>);
      return originalPlanConfig.planner_items
        .map((originalPlannerItem) => {
          const originalParentPlannerItem = originalPlannerItem.parent_id
            ? originalPlannerItemsById[originalPlannerItem.parent_id]
            : null;
          if (originalParentPlannerItem === undefined) {
            throw this.createError("Missing parent planner item", originalPlannerItem);
          }
          const plannerItem = plannerItemsBySourceId[originalPlannerItem.source_id];
          if (!plannerItem) {
            return null;
          }
          const parentPlannerItem = plannerItem.parent_id
            ? plannerItemsById[plannerItem.parent_id]
            : null;
          if (parentPlannerItem === undefined) {
            throw this.createError("Missing parent planner item", plannerItem);
          }
          return {
            _id: `updatedParentPlannerItem_${originalPlannerItem._id}`,
            plannerItem: originalPlannerItem,
            from: originalParentPlannerItem,
            to: parentPlannerItem,
          };
        })
        .filter((change) => {
          const isChanged = change && change.from?.source_id !== change.to?.source_id;
          if (isChanged) {
            const addedChange = change.to && addedPlannerItemChangesById[change.to?._id];
            if (addedChange) {
              // filter out change, when new parent is an added planner item, which is not selected
              return !selectedChangeIds || selectedChangeIds.has(addedChange._id);
            }
          }
          return isChanged;
        }) as Change[];
    },
    getPlannerItemMaps(originalPlanConfig: PlanConfig, planConfig: PlanConfig): PlannerItemMaps {
      const originalPlannerItemsById = originalPlanConfig.planner_items.reduce(
        (acc, plannerItem) => {
          acc[plannerItem._id] = plannerItem;
          return acc;
        },
        {} as PlannerItemMap,
      );
      const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
        acc[plannerItem._id] = plannerItem;
        return acc;
      }, {} as PlannerItemMap);

      const originalPlannerItemsBySourceId = originalPlanConfig.planner_items.reduce(
        (acc, plannerItem) => {
          acc[plannerItem.source_id] = plannerItem;
          return acc;
        },
        {} as PlannerItemMap,
      );
      const plannerItemsBySourceId = planConfig.planner_items.reduce((acc, plannerItem) => {
        acc[plannerItem.source_id] = plannerItem;
        return acc;
      }, {} as PlannerItemMap);

      const originalPlannedEventsBySourceId = originalPlanConfig.planned_events.reduce(
        (acc, originalPlannedEvent) => {
          const originalPlannerItem =
            originalPlannerItemsById[originalPlannedEvent.planner_item_id];
          if (!originalPlannerItem) {
            throw this.createError(
              "Missing original planner item for original planned event",
              originalPlannedEvent,
            );
          }
          acc[originalPlannerItem.source_id] = originalPlannedEvent;
          return acc;
        },
        {} as Record<string, PlannedEvent | undefined>,
      );

      const plannedEventsBySourceId = planConfig.planned_events.reduce((acc, plannedEvent) => {
        const plannerItem = plannerItemsById[plannedEvent.planner_item_id];
        if (!plannerItem) {
          throw this.createError("Missing planner item for planned event", plannedEvent);
        }
        acc[plannerItem.source_id] = plannedEvent;
        return acc;
      }, {} as Record<string, PlannedEvent | undefined>);

      const originalPlannedEventsByPlannerItemId = originalPlanConfig.planned_events.reduce(
        (acc, originalPlannedEvent) => {
          acc[originalPlannedEvent.planner_item_id] = originalPlannedEvent;
          return acc;
        },
        {} as Record<string, PlannedEvent | undefined>,
      );
      const originalPlannedEventsById = originalPlanConfig.planned_events.reduce(
        (acc, plannedEvent) => {
          acc[plannedEvent._id] = plannedEvent;
          return acc;
        },
        {} as Record<string, PlannedEvent | undefined>,
      );
      const plannedEventsById = planConfig.planned_events.reduce((acc, plannedEvent) => {
        acc[plannedEvent._id] = plannedEvent;
        return acc;
      }, {} as Record<string, PlannedEvent | undefined>);
      const plannedEventsByPlannerItemId = planConfig.planned_events.reduce((acc, plannedEvent) => {
        acc[plannedEvent.planner_item_id] = plannedEvent;
        return acc;
      }, {} as Record<string, PlannedEvent | undefined>);

      const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
        acc[actualEvent.source_id] = actualEvent;
        return acc;
      }, {} as Record<string, ActualEvent | undefined>);

      return {
        originalPlannerItemsById,
        plannerItemsById,
        originalPlannerItemsBySourceId,
        plannerItemsBySourceId,
        originalPlannedEventsBySourceId,
        plannedEventsBySourceId,
        originalPlannedEventsByPlannerItemId,
        originalPlannedEventsById,
        plannedEventsById,
        plannedEventsByPlannerItemId,
        actualEventsBySourceId,
      };
    },
    getConnectedPlannedEvents(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      {
        plannedEventsBySourceId,
        plannerItemsById,
        originalPlannerItemsById,
        originalPlannerItemsBySourceId,
        originalPlannedEventsByPlannerItemId,
        originalPlannedEventsById,
        plannedEventsById,
        plannedEventsByPlannerItemId,
        plannerItemsBySourceId,
      }: PlannerItemMaps,
      changes: Pick<Changes, "addedPlannerItems">,
      selectedChangeIds: Set<string> | null,
    ): Change<PlannerItem>[] {
      const addedPlannerItemChangesById = changes.addedPlannerItems.reduce((acc, change) => {
        acc[change.plannerItem._id] = change;
        return acc;
      }, {} as Record<string, Change | undefined>);
      return [
        ...originalPlanConfig.planner_items.flatMap((originalPlannerItem) => {
          const originalPlannedEvent =
            originalPlannedEventsByPlannerItemId[originalPlannerItem._id];
          const plannedEvent = plannedEventsBySourceId[originalPlannerItem.source_id];
          if (!originalPlannedEvent || !plannedEvent) {
            return [];
          }
          const originalPredecessors = originalPlannedEvent.predecessor_ids.map((predecessorId) => {
            const originalPredecessorEvent = originalPlannedEventsById[predecessorId];
            if (!originalPredecessorEvent) {
              throw this.createError("Missing original predecessor event", predecessorId);
            }
            const originalPredecessorPlannerItem =
              originalPlannerItemsById[originalPredecessorEvent.planner_item_id];
            if (!originalPredecessorPlannerItem) {
              throw this.createError(
                "Missing original predecessor planner item for original planned event",
                originalPredecessorEvent,
              );
            }
            return originalPredecessorPlannerItem.source_id;
          });
          const predecessors = plannedEvent.predecessor_ids.map((predecessorId) => {
            const predecessorEvent = plannedEventsById[predecessorId];
            if (!predecessorEvent) {
              throw this.createError("Missing predecessor event", predecessorId);
            }
            const predecessorPlannerItem = plannerItemsById[predecessorEvent.planner_item_id];
            if (!predecessorPlannerItem) {
              throw this.createError(
                "Missing predecessor planner item for planned event",
                predecessorPlannerItem,
              );
            }
            return predecessorPlannerItem.source_id;
          });
          const addedDependencies = predecessors.filter(
            (sourceId) => !originalPredecessors.includes(sourceId),
          );
          return addedDependencies
            .map((sourceId) => {
              const originalFromPlannerItem = originalPlannerItemsBySourceId[sourceId];
              if (originalFromPlannerItem) {
                return {
                  _id: `added_dependency_${originalFromPlannerItem._id}_${originalPlannerItem._id}`,
                  plannerItem: originalFromPlannerItem,
                  from: originalFromPlannerItem,
                  to: originalPlannerItem,
                };
              }
              const fromPlannerItem = plannerItemsBySourceId[sourceId];
              if (!fromPlannerItem) {
                throw this.createError("Missing from planner item", sourceId);
              }
              const addedChange = addedPlannerItemChangesById[fromPlannerItem._id];
              if (addedChange && (!selectedChangeIds || selectedChangeIds.has(addedChange._id))) {
                return {
                  id: `added_dependency_${fromPlannerItem._id}_${originalPlannerItem._id}`,
                  plannerItem: fromPlannerItem,
                  from: fromPlannerItem,
                  to: originalPlannerItem,
                };
              }
              return null;
            })
            .filter((change) => change) as Change<PlannerItem>[];
        }),
        // dependencies of added planner items
        ...changes.addedPlannerItems.flatMap((change) => {
          if (selectedChangeIds && !selectedChangeIds.has(change._id)) {
            return [];
          }
          const plannedEvent = plannedEventsByPlannerItemId[change.plannerItem._id];
          if (!plannedEvent) {
            return [];
          }
          return plannedEvent.predecessor_ids
            .map((predecessorId) => {
              const predecessorEvent = plannedEventsById[predecessorId];
              if (!predecessorEvent) {
                throw this.createError("Missing predecessor event", predecessorId);
              }
              const predecessorPlannerItem = plannerItemsById[predecessorEvent.planner_item_id];
              if (!predecessorPlannerItem) {
                throw this.createError("Missing predecessor planner item", predecessorPlannerItem);
              }
              const originalPredecessorPlannerItem =
                originalPlannerItemsBySourceId[predecessorPlannerItem.source_id];
              // only the ones which are pointing to the original plan
              // dependencies between added ones are taken automatically
              if (originalPredecessorPlannerItem) {
                const originalPredecessorPlannedEvent =
                  originalPlannedEventsByPlannerItemId[originalPredecessorPlannerItem._id];
                // if the original plan has a planner item, but no event, then the added connection is skipped,
                // because we are not taking only added planned events, always both
                if (originalPredecessorPlannedEvent) {
                  return {
                    _id: `connectedDependency_${originalPredecessorPlannerItem._id}_${change.plannerItem._id}`,
                    plannerItem: originalPredecessorPlannerItem,
                    from: originalPredecessorPlannerItem,
                    to: change.plannerItem,
                  };
                }
              }
              return null;
            })
            .filter((change) => change) as Change<PlannerItem>[];
        }),
      ];
    },
    getDisconnectedPlannedEvents(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      {
        plannedEventsBySourceId,
        plannerItemsById,
        originalPlannerItemsById,
        originalPlannedEventsByPlannerItemId,
        originalPlannedEventsById,
        plannedEventsById,
        plannerItemsBySourceId,
      }: PlannerItemMaps,
    ): Change<PlannerItem>[] {
      return originalPlanConfig.planner_items.flatMap((originalPlannerItem) => {
        const originalPlannedEvent = originalPlannedEventsByPlannerItemId[originalPlannerItem._id];
        const plannedEvent = plannedEventsBySourceId[originalPlannerItem.source_id];
        if (!originalPlannedEvent || !plannedEvent) {
          return [];
        }
        const originalPredecessorPlannerItems = originalPlannedEvent.predecessor_ids.map(
          (predecessorId) => {
            const originalPredecessorEvent = originalPlannedEventsById[predecessorId];
            if (!originalPredecessorEvent) {
              throw this.createError("Missing original predecessor event", predecessorId);
            }
            const originalPredecessorPlannerItem =
              originalPlannerItemsById[originalPredecessorEvent.planner_item_id];
            if (!originalPredecessorPlannerItem) {
              throw this.createError(
                "Missing original predecessor planner item",
                originalPredecessorEvent,
              );
            }
            return originalPredecessorPlannerItem;
          },
        );
        const predecessorPlannerItems = plannedEvent.predecessor_ids.map((predecessorId) => {
          const predecessorEvent = plannedEventsById[predecessorId];
          if (!predecessorEvent) {
            throw this.createError("Missing predecessor event", predecessorId);
          }
          const predecessorPlannerItem = plannerItemsById[predecessorEvent.planner_item_id];
          if (!predecessorPlannerItem) {
            throw this.createError("Missing predecessor planner item", predecessorPlannerItem);
          }
          return predecessorPlannerItem;
        });
        const removedPredecessorPlannerItems = originalPredecessorPlannerItems.filter(
          (originalPredecessor) =>
            predecessorPlannerItems.every(
              (predecessor) => predecessor.source_id !== originalPredecessor.source_id,
            ),
        );
        return removedPredecessorPlannerItems
          .filter((originalPredecessor) => plannerItemsBySourceId[originalPredecessor.source_id])
          .map((originalPredecessorPlannerItem) => ({
            _id: `disconnectedDependency_${originalPredecessorPlannerItem._id}_${originalPlannerItem._id}`,
            plannerItem: originalPredecessorPlannerItem,
            from: originalPredecessorPlannerItem,
            to: originalPlannerItem,
          }));
      });
    },
    getChanges(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      selectedChangeIds: Set<string> | null,
      merges: Record<string, string>,
    ): Changes {
      const plannerItemMaps = this.getPlannerItemMaps(originalPlanConfig, planConfig);
      const updatedPlannerItems = this.getUpdatedPlannerItem(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
      );
      const deletedPlannerItems = this.getDeletedPlannerItems(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
        selectedChangeIds,
        merges,
      );
      const addedPlannerItems = this.getAddedPlannerItems(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
      );
      const updatedParentPlannerItems = this.getUpdatedParentPlannerItem(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
        { addedPlannerItems },
        selectedChangeIds,
      );
      const disconnectedPlannedEvents = this.getDisconnectedPlannedEvents(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
      );
      const connectedPlannedEvents = this.getConnectedPlannedEvents(
        originalPlanConfig,
        planConfig,
        plannerItemMaps,
        { addedPlannerItems },
        selectedChangeIds,
      );
      const changes = {
        deletedPlannerItems,
        addedPlannerItems,
        updatedPlannerItems,
        updatedParentPlannerItems,
        connectedPlannedEvents,
        disconnectedPlannedEvents,
      };
      const changesMaps = this.getChangesMaps(changes);
      return {
        updatedPlan: this.getPlanChanges(originalPlanConfig, planConfig),
        updatedPlannedEvents: this.getPlannedEventChanges(
          originalPlanConfig,
          planConfig,
          plannerItemMaps,
          changes,
          selectedChangeIds,
          merges,
          changesMaps,
        ),
        updatedPlannerItems,
        deletedPlannerItems,
        addedPlannerItems,
        updatedParentPlannerItems,
        connectedPlannedEvents,
        disconnectedPlannedEvents,
      };
    },
    getMergedPredecessorIds(
      originalPlannedEvent: PlannedEvent,
      changes: Changes,
      selectedChangeIds: Set<string>,
      {
        originalPlannedEventsById,
        originalPlannedEventsByPlannerItemId,
        plannedEventsByPlannerItemId,
      }: PlannerItemMaps,
      {
        deletedPlannerItemChangesById,
        disconnectedDependenciesByFromId,
        addedPlannerItemChangesById,
      }: ChangesMaps,
      mergesByTo: Record<string, string>,
    ): string[] {
      return [
        ...originalPlannedEvent.predecessor_ids.filter((predecessorId) => {
          const predecessorPlannedEvent = originalPlannedEventsById[predecessorId];
          if (!predecessorPlannedEvent) {
            throw this.createError("Missing predecessor planned event", predecessorId);
          }
          const disconnectedDependencyChange =
            disconnectedDependenciesByFromId[predecessorPlannedEvent.planner_item_id];
          if (
            disconnectedDependencyChange &&
            selectedChangeIds.has(disconnectedDependencyChange._id) &&
            disconnectedDependencyChange.to._id === originalPlannedEvent.planner_item_id
          ) {
            // disconnected dependency
            return false;
          }
          const deletedPlannerItemChange =
            deletedPlannerItemChangesById[predecessorPlannedEvent.planner_item_id];
          if (deletedPlannerItemChange && selectedChangeIds.has(deletedPlannerItemChange._id)) {
            // deleted planner item
            return false;
          }
          const mergeChangeId = mergesByTo[predecessorPlannedEvent.planner_item_id];
          if (mergeChangeId && selectedChangeIds.has(mergeChangeId)) {
            // merged event need to be filtered out
            // because the added ones are preferred
            return false;
          }
          return true;
        }),
        // added ones
        ...(changes.connectedPlannedEvents
          .filter(
            (change) =>
              selectedChangeIds.has(change._id) &&
              change.to._id === originalPlannedEvent.planner_item_id,
          )
          .map((change) => {
            const addedChange = addedPlannerItemChangesById[change.from._id];
            if (addedChange) {
              if (selectedChangeIds.has(addedChange._id)) {
                const plannedEvent = plannedEventsByPlannerItemId[change.from._id];
                if (!plannedEvent) {
                  throw this.createError("Missing planned event", change);
                }
                return plannedEvent._id;
              }
              return null;
            }
            const originalPlannedEvent = originalPlannedEventsByPlannerItemId[change.from._id];
            if (!originalPlannedEvent) {
              throw this.createError("Missing original planned event", change);
            }
            return originalPlannedEvent._id;
          })
          .filter((predecessorId) => predecessorId) as string[]),
      ];
    },
    getMergedPredecessorIdsForAddedPlannedEvents(
      plannedEvent: PlannedEvent,
      changes: Changes,
      selectedChangeIds: Set<string>,
      {
        plannedEventsById,
        plannerItemsById,
        originalPlannedEventsByPlannerItemId,
        originalPlannerItemsBySourceId,
      }: PlannerItemMaps,
      { addedPlannerItemChangesById, connectedDependenciesByFromId }: ChangesMaps,
    ): string[] {
      return plannedEvent.predecessor_ids
        .map((predecessorId) => {
          const predecessorPlannedEvent = plannedEventsById[predecessorId];
          if (!predecessorPlannedEvent) {
            throw new Error("Missing predecessor planned event", predecessorPlannedEvent);
          }
          const addedChange = addedPlannerItemChangesById[predecessorPlannedEvent.planner_item_id];
          if (addedChange) {
            if (selectedChangeIds.has(addedChange._id)) {
              return predecessorId;
            }
            return null;
          }
          const predecessorPlannerItem = plannerItemsById[predecessorPlannedEvent.planner_item_id];
          if (!predecessorPlannerItem) {
            throw this.createError("Missing predecessor planner item", predecessorPlannedEvent);
          }
          const originalPredecessorPlannerItem =
            originalPlannerItemsBySourceId[predecessorPlannerItem.source_id];
          if (!originalPredecessorPlannerItem) {
            throw this.createError(
              "Missing original predecessor planner item",
              predecessorPlannerItem,
            );
          }
          const connectedChange = connectedDependenciesByFromId[originalPredecessorPlannerItem._id];
          if (connectedChange && selectedChangeIds.has(connectedChange._id)) {
            const originalPlannedEvent =
              originalPlannedEventsByPlannerItemId[originalPredecessorPlannerItem._id];
            if (!originalPlannedEvent) {
              throw this.createError(
                "Missing original planned event for predecessor planner item",
                predecessorPlannerItem,
              );
            }
            return originalPlannedEvent._id;
          }
          return null;
        })
        .filter((predecessorId) => predecessorId) as string[];
    },
    getMergedPlannedEvents(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      mergedPlan: Plan,
      changes: Changes,
      plannerItemMaps: PlannerItemMaps,
      selectedChangeIds: Set<string>,
      merges: Record<string, string>,
      changesMaps: ChangesMaps,
    ) {
      const { deletedPlannerItemChangesById, addedPlannerItemChangesById } = changesMaps;
      const plannedEventChangesById = changes.updatedPlannedEvents.reduce(
        (acc, plannedEventChange) => {
          acc[plannedEventChange.from._id] = plannedEventChange;
          return acc;
        },
        {} as Record<string, Change<PlannedEvent> | undefined>,
      );
      const mergesByTo = Object.entries(merges).reduce((acc, [changeId, to]) => {
        acc[to] = changeId;
        return acc;
      }, {} as Record<string, string>);
      return [
        ...originalPlanConfig.planned_events
          .map((originalPlannedEvent) => {
            const predecessorIds = this.getMergedPredecessorIds(
              originalPlannedEvent,
              changes,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
              mergesByTo,
            );
            const plannedEventChange = plannedEventChangesById[originalPlannedEvent._id];
            if (plannedEventChange && selectedChangeIds.has(plannedEventChange._id)) {
              // updated planned event stat/end date
              return {
                ...originalPlannedEvent,
                start: plannedEventChange.to.start,
                end: plannedEventChange.to.end,
                predecessor_ids: predecessorIds,
              };
            }
            return {
              ...originalPlannedEvent,
              predecessor_ids: predecessorIds,
            };
          })
          .filter((originalPlannedEvent) => {
            const change = deletedPlannerItemChangesById[originalPlannedEvent.planner_item_id];
            if (change && selectedChangeIds.has(change._id)) {
              // deleted planner items
              return false;
            }
            const mergeChangeId = mergesByTo[originalPlannedEvent.planner_item_id];
            if (mergeChangeId && selectedChangeIds.has(mergeChangeId)) {
              // merged events need to be filtered out
              // because the added ones are preferred
              return false;
            }
            return true;
          }),
        // added planner items
        ...planConfig.planned_events
          .filter((plannedEvent) => {
            const change = addedPlannerItemChangesById[plannedEvent.planner_item_id];
            return change && selectedChangeIds.has(change._id);
          })
          .map((plannedEvent) => ({
            ...plannedEvent,
            predecessor_ids: this.getMergedPredecessorIdsForAddedPlannedEvents(
              plannedEvent,
              changes,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
            ),
          })),
      ].map((plannedEvent) => ({
        ...plannedEvent,
        plan_id: mergedPlan._id,
      }));
    },
    getMergedPlan(originalPlan: Plan, changes: Changes, selectedChangeIds: Set<string>) {
      return {
        ...originalPlan,
        // it's important to set a new temporary id to make the Scheduler component completely re-render
        _id: uuidv4(),
        name:
          // updated plan name
          changes.updatedPlan && selectedChangeIds.has(changes.updatedPlan._id)
            ? changes.updatedPlan.to.name
            : originalPlan.name,
      };
    },
    getMergedPlannerItemParentId(
      originalPlannerItem: PlannerItem,
      selectedChangeIds: Set<string> | null,
      plannerItemMaps: PlannerItemMaps,
      changesMaps: ChangesMaps,
      mergesByTo: Record<string, string>,
    ): string | null {
      const { originalPlannerItemsBySourceId } = plannerItemMaps;
      const {
        addedPlannerItemChangesById,
        deletedPlannerItemChangesById,
        parentPlannerItemChangesById,
        addedPlannerItemChangesByChangeId,
      } = changesMaps;
      const parentChange = parentPlannerItemChangesById[originalPlannerItem._id];
      if (parentChange && (!selectedChangeIds || selectedChangeIds.has(parentChange._id))) {
        if (parentChange.to) {
          const addedChange = addedPlannerItemChangesById[parentChange.to._id];
          if (addedChange) {
            // updated parent to an added planner item
            return !selectedChangeIds || selectedChangeIds.has(addedChange._id)
              ? addedChange.plannerItem._id
              : null;
          }
          const originalParentPlannerItem =
            originalPlannerItemsBySourceId[parentChange.to.source_id];
          if (!originalParentPlannerItem) {
            throw this.createError("Missing updated parent for planner item", parentChange);
          }
          // updated parent to an existing planner item
          return originalParentPlannerItem._id;
        }
        // updated parent to root
        return null;
      }
      if (originalPlannerItem.parent_id) {
        const change = deletedPlannerItemChangesById[originalPlannerItem.parent_id];
        if (change && selectedChangeIds && selectedChangeIds.has(change._id)) {
          // deleted parent planner item, find the next available parent
          return this.getMergedPlannerItemParentId(
            change.plannerItem,
            selectedChangeIds,
            plannerItemMaps,
            changesMaps,
            mergesByTo,
          );
        }
        const mergeChangeId = mergesByTo[originalPlannerItem.parent_id];
        if (mergeChangeId && selectedChangeIds && selectedChangeIds.has(mergeChangeId)) {
          const addedChange = addedPlannerItemChangesByChangeId[mergeChangeId];
          if (!addedChange) {
            throw this.createError("Missing selected added change", mergeChangeId);
          }
          // reattach children to merged parent
          return addedChange.plannerItem._id;
        }
      }
      return originalPlannerItem.parent_id;
    },
    getMergedAddedPlannerItemParentId(
      plannerItem: PlannerItem,
      selectedChangeIds: Set<string> | null,
      plannerItemMaps: PlannerItemMaps,
      changesMaps: ChangesMaps,
    ): string | null {
      if (!plannerItem.parent_id) {
        return null;
      }

      const { plannerItemsById, originalPlannerItemsBySourceId } = plannerItemMaps;
      const { addedPlannerItemChangesById } = changesMaps;

      const parentPlannerItem = plannerItemsById[plannerItem.parent_id];
      if (!parentPlannerItem) {
        throw this.createError("Missing parent for planner item", plannerItem);
      }

      const parentPlannerItemChange = addedPlannerItemChangesById[parentPlannerItem._id];
      if (
        parentPlannerItemChange &&
        selectedChangeIds &&
        !selectedChangeIds.has(parentPlannerItemChange._id)
      ) {
        // parent of added planner item is also added, but not selected, find the next available parent
        return this.getMergedAddedPlannerItemParentId(
          parentPlannerItem,
          selectedChangeIds,
          plannerItemMaps,
          changesMaps,
        );
      }

      const originalParentPlannerItem = originalPlannerItemsBySourceId[parentPlannerItem.source_id];
      if (originalParentPlannerItem) {
        // added planner item's parent already exists
        return originalParentPlannerItem._id;
      }

      // added planner item's parent also added and selected
      return plannerItem.parent_id;
    },
    getMergedPlannerItemTrackingEnabled(
      originalPlannerItem: PlannerItem,
      selectedChangeIds: Set<string> | null,
      plannerItemMaps: PlannerItemMaps,
      changesMaps: ChangesMaps,
      merges: Record<string, string>,
      originalPlannerItemChildrenById: Record<string, PlannerItem[]>,
      parentId: string | null,
    ) {
      const { originalPlannerItemsById, actualEventsBySourceId } = plannerItemMaps;
      const { addedPlannerItemChangesById } = changesMaps;

      const actualEvent = actualEventsBySourceId[originalPlannerItem.source_id];
      if (!actualEvent) {
        if (parentId) {
          const originalParentPlannerItem = originalPlannerItemsById[parentId];
          if (originalParentPlannerItem) {
            const children = originalPlannerItemChildrenById[parentId] || [];
            if (children.length === 0) {
              // when parent already existed in the original plan and had no children
              return false;
            }
          }
          const addedChange = addedPlannerItemChangesById[parentId];
          if (addedChange && (!selectedChangeIds || selectedChangeIds.has(addedChange._id))) {
            const mergeTo = merges[addedChange._id];
            if (mergeTo) {
              const children = originalPlannerItemChildrenById[mergeTo] || [];
              if (children.length === 0) {
                // when parent is added, merged with an existing planner item and
                // the existing planner item had no children
                return false;
              }
            }
          }
        }
      }

      return originalPlannerItem.tracking_enabled;
    },
    getMergedAddedPlannerItemTrackingEnabled(
      plannerItem: PlannerItem,
      selectedChangeIds: Set<string> | null,
      plannerItemMaps: PlannerItemMaps,
      changesMaps: ChangesMaps,
      merges: Record<string, string>,
      originalPlannerItemChildrenById: Record<string, PlannerItem[]>,
      parentId: string | null,
    ) {
      const { originalPlannerItemsById } = plannerItemMaps;
      const { addedPlannerItemChangesById } = changesMaps;
      if (parentId) {
        const originalParentPlannerItem = originalPlannerItemsById[parentId];
        if (originalParentPlannerItem) {
          const children = originalPlannerItemChildrenById[parentId] || [];
          if (children.length === 0) {
            // when parent already existed in the original plan and had no children
            return false;
          }
        }
        const addedChange = addedPlannerItemChangesById[parentId];
        if (addedChange && (!selectedChangeIds || selectedChangeIds.has(addedChange._id))) {
          const mergeTo = merges[addedChange._id];
          if (mergeTo) {
            const children = originalPlannerItemChildrenById[mergeTo] || [];
            if (children.length === 0) {
              // when parent is added, merged with an existing planner item and
              // the existing planner item had no children
              return false;
            }
          }
        }
      }
      return true;
    },
    getMergedPlannerItems(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      mergedPlan: Plan,
      changes: Pick<
        Changes,
        | "updatedPlannerItems"
        | "deletedPlannerItems"
        | "addedPlannerItems"
        | "updatedParentPlannerItems"
      >,
      plannerItemMaps: PlannerItemMaps,
      selectedChangeIds: Set<string> | null,
      merges: Record<string, string>,
      changesMaps: ChangesMaps,
    ) {
      const { plannerItemChangesById, deletedPlannerItemChangesById, addedPlannerItemChangesById } =
        changesMaps;
      const mergesByTo = Object.entries(merges).reduce((acc, [changeId, to]) => {
        acc[to] = changeId;
        return acc;
      }, {} as Record<string, string>);
      const originalPlannerItemChildrenById = originalPlanConfig.planner_items.reduce(
        (acc, plannerItem) => {
          if (plannerItem.parent_id) {
            if (acc[plannerItem.parent_id]) {
              acc[plannerItem.parent_id].push(plannerItem);
            } else {
              acc[plannerItem.parent_id] = [plannerItem];
            }
          }
          return acc;
        },
        {} as Record<string, PlannerItem[]>,
      );
      return [
        ...originalPlanConfig.planner_items
          .map((originalPlannerItem) => {
            const plannerItemChange = plannerItemChangesById[originalPlannerItem._id];
            const name =
              plannerItemChange &&
              (!selectedChangeIds || selectedChangeIds.has(plannerItemChange._id))
                ? plannerItemChange.to.name
                : originalPlannerItem.name;
            const parentId = this.getMergedPlannerItemParentId(
              originalPlannerItem,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
              mergesByTo,
            );
            const trackingEnabled = this.getMergedPlannerItemTrackingEnabled(
              originalPlannerItem,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
              merges,
              originalPlannerItemChildrenById,
              parentId,
            );
            // updated planner item's name, parent and tracking_enabledz
            return {
              ...originalPlannerItem,
              name,
              parent_id: parentId,
              tracking_enabled: trackingEnabled,
            };
          })
          .filter((originalPlannerItem) => {
            const change = deletedPlannerItemChangesById[originalPlannerItem._id];
            if (change && selectedChangeIds?.has(change._id)) {
              return false;
            }
            const mergeChangeId = mergesByTo[originalPlannerItem._id];
            if (mergeChangeId && selectedChangeIds?.has(mergeChangeId)) {
              // merged planner items need to be filtered out
              // because the added ones are preferred
              return false;
            }
            return true;
          }),
        // added planner items
        ...planConfig.planner_items
          .filter((plannerItem) => {
            const change = addedPlannerItemChangesById[plannerItem._id];
            return change && (!selectedChangeIds || selectedChangeIds.has(change._id));
          })
          .map((plannerItem) => {
            const parentId = this.getMergedAddedPlannerItemParentId(
              plannerItem,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
            );
            const trackingEnabled = this.getMergedAddedPlannerItemTrackingEnabled(
              plannerItem,
              selectedChangeIds,
              plannerItemMaps,
              changesMaps,
              merges,
              originalPlannerItemChildrenById,
              parentId,
            );
            return {
              ...plannerItem,
              parent_id: parentId,
              tracking_enabled: trackingEnabled,
            };
          }),
      ].map((plannerItem) => ({
        ...plannerItem,
        plan_id: mergedPlan._id,
      }));
    },
    getChangesMaps(
      changes: Pick<
        Changes,
        | "updatedPlannerItems"
        | "deletedPlannerItems"
        | "addedPlannerItems"
        | "updatedParentPlannerItems"
        | "connectedPlannedEvents"
        | "disconnectedPlannedEvents"
      >,
    ): ChangesMaps {
      const plannerItemChangesById = changes.updatedPlannerItems.reduce(
        (acc, plannerItemChange) => {
          acc[plannerItemChange.from._id] = plannerItemChange;
          return acc;
        },
        {} as Record<string, Change<PlannerItem> | undefined>,
      );
      const deletedPlannerItemChangesById = changes.deletedPlannerItems.reduce((acc, change) => {
        acc[change.plannerItem._id] = change;
        return acc;
      }, {} as Record<string, Change | undefined>);
      const addedPlannerItemChangesById = changes.addedPlannerItems.reduce((acc, change) => {
        acc[change.plannerItem._id] = change;
        return acc;
      }, {} as Record<string, Change | undefined>);
      const addedPlannerItemChangesByChangeId = changes.addedPlannerItems.reduce((acc, change) => {
        acc[change._id] = change;
        return acc;
      }, {} as Record<string, Change | undefined>);
      const parentPlannerItemChangesById = changes.updatedParentPlannerItems.reduce(
        (acc, change) => {
          acc[change.plannerItem._id] = change;
          return acc;
        },
        {} as Record<string, Change | undefined>,
      );
      const connectedDependenciesByFromId = changes.connectedPlannedEvents.reduce((acc, change) => {
        acc[change.from._id] = change;
        return acc;
      }, {} as Record<string, Change<PlannerItem>>);
      const disconnectedDependenciesByFromId = changes.disconnectedPlannedEvents.reduce(
        (acc, change) => {
          acc[change.from._id] = change;
          return acc;
        },
        {} as Record<string, Change<PlannerItem>>,
      );
      return {
        plannerItemChangesById,
        deletedPlannerItemChangesById,
        addedPlannerItemChangesById,
        parentPlannerItemChangesById,
        connectedDependenciesByFromId,
        disconnectedDependenciesByFromId,
        addedPlannerItemChangesByChangeId,
      };
    },
    getMergedActualEvents(
      originalPlanConfig: PlanConfig,
      changes: Changes,
      { originalPlannerItemsBySourceId }: PlannerItemMaps,
      selectedChangeIds: Set<string>,
      merges: Record<string, string>,
    ) {
      const mergesByTo = Object.entries(merges).reduce((acc, [changeId, to]) => {
        acc[to] = changeId;
        return acc;
      }, {} as Record<string, string>);
      const addedPlannerItemChangesByChangeId = changes.addedPlannerItems.reduce((acc, change) => {
        acc[change._id] = change;
        return acc;
      }, {} as Record<string, Change>);
      return originalPlanConfig.actual_events.map((actualEvent) => {
        const originalPlannerItem = originalPlannerItemsBySourceId[actualEvent.source_id];
        if (originalPlannerItem) {
          const mergeChangeId = mergesByTo[originalPlannerItem._id];
          if (mergeChangeId && selectedChangeIds.has(mergeChangeId)) {
            const addedChange = addedPlannerItemChangesByChangeId[mergeChangeId];
            if (!addedChange) {
              throw this.createError("Missing added change", mergeChangeId);
            }
            return {
              ...actualEvent,
              source_id: addedChange.plannerItem.source_id,
            };
          }
        }
        return actualEvent;
      });
    },
    getMergedPlanConfig(
      originalPlanConfig: PlanConfig,
      planConfig: PlanConfig,
      changes: Changes,
      selectedChangeIds: Set<string>,
      merges: Record<string, string>,
    ) {
      const plannerItemMaps = this.getPlannerItemMaps(originalPlanConfig, planConfig);
      const changesMaps = this.getChangesMaps(changes);
      const plan = this.getMergedPlan(originalPlanConfig.plan, changes, selectedChangeIds);
      return {
        ...originalPlanConfig,
        plan,
        planned_events: this.getMergedPlannedEvents(
          originalPlanConfig,
          planConfig,
          plan,
          changes,
          plannerItemMaps,
          selectedChangeIds,
          merges,
          changesMaps,
        ),
        planner_items: this.getMergedPlannerItems(
          originalPlanConfig,
          planConfig,
          plan,
          changes,
          plannerItemMaps,
          selectedChangeIds,
          merges,
          changesMaps,
        ),
        actual_events: this.getMergedActualEvents(
          originalPlanConfig,
          changes,
          plannerItemMaps,
          selectedChangeIds,
          merges,
        ),
      } as PlanConfig;
    },
    getChangesAsHierarchy<TEntity>(changes: Change<TEntity>[]) {
      const plannerItemsById = changes.reduce((acc, change) => {
        acc[change.plannerItem._id] = change;
        return acc;
      }, {} as Record<string, { _id: string; plannerItem: PlannerItem }>);
      const mapChange = (change: Change<TEntity>): HierarchicalChange<TEntity> => {
        return {
          ...change,
          children: changes
            .filter(({ plannerItem }) => plannerItem.parent_id === change.plannerItem._id)
            .map((change) => mapChange(change)),
        };
      };
      return changes
        .filter(
          (change) =>
            !change.plannerItem.parent_id || !plannerItemsById[change.plannerItem.parent_id],
        )
        .map((change) => mapChange(change));
    },
    createError<TPayload>(message: string, payload: TPayload) {
      const error = new Error(message) as Error & {
        payload: TPayload;
      };
      error.payload = payload;
      return error;
    },
  },
};
