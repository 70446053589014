<template>
  <div class="text-sm px-2 py-0.5 flex justify-between text-gray-600 bg-gray-200 relative">
    <span>{{ formatDate(event.start) }}</span>
    <span class="absolute top-0 left-1/2" style="margin-left: -3px">-</span
    ><span>{{ event.end ? formatDate(event.end) : "now" }}</span>
  </div>
</template>

<script lang="ts">
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ActualEvent } from "oai-services";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ActualsChangelogEventItem",
  props: {
    event: {
      type: Object as PropType<ActualEvent<string>>,
      required: true,
    },
  },
  methods: {
    formatDate(dateText: string) {
      return format(toZonedTime(parseISO(dateText), "UTC"), "dd.MM.yyyy HH:mm");
    },
  },
});
</script>
