import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { apiClient } from "@/repositories/clients";
import { Project, ProjectLevelStatus, ProjectStatus } from "@/types/Project";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

type ProjectToUpdate = Partial<
  Pick<
    Project,
    | "name"
    | "status"
    | "planned_start"
    | "planned_end"
    | "country_code"
    | "zip_code"
    | "timezone"
    | "street"
    | "features"
  >
>;

const formatProjectToUpdate = (project: ProjectToUpdate) => ({
  ...project,
  planned_start: project.planned_start && formatUtcDate(project.planned_start),
  planned_end: project.planned_end && formatUtcDate(project.planned_end),
});

const formatProjectToCreate = (
  project: Omit<Project, "_id" | "created" | "created_by" | "updated" | "updated_by">,
) => ({
  ...project,
  planned_start: formatUtcDate(project.planned_start),
  planned_end: project.planned_end ? formatUtcDate(project.planned_end) : project.planned_end,
});

const mapProject = (project: Project<string>): Project => ({
  ...project,
  planned_start: parseUtcDate(project.planned_start),
  planned_end:
    project.planned_end === null ? project.planned_end : parseUtcDate(project.planned_end),
});

const loadAllProjects = (projectStatus: ProjectStatus | null = null): Promise<Project[]> => {
  const searchParams = new URLSearchParams();
  searchParams.set("full_object", "1");
  if (projectStatus) {
    searchParams.set("project_status", projectStatus);
  }

  return apiClient
    .get<void, AxiosResponse<Project<string>[]>>(`project/?${searchParams.toString()}`)
    .then((response) => response.data.map((project) => mapProject(project)));
};

const loadProject = (customerName: string, siteId: string): Promise<Project> =>
  apiClient
    .get<void, AxiosResponse<Project<string>>>(`/project/${customerName}/${siteId}`)
    .then((response) => mapProject(response.data));

const updateProject = (
  customerName: string,
  siteId: string,
  projectToUpdate: ProjectToUpdate,
): Promise<Project> =>
  apiClient
    .patch<void, AxiosResponse<Project<string>>>(
      `/project/${customerName}/${siteId}`,
      formatProjectToUpdate(projectToUpdate),
    )
    .then((response) => mapProject(response.data));

const createProject = (
  project: Omit<Project, "_id" | "created" | "created_by" | "updated" | "updated_by">,
): Promise<Project> =>
  apiClient
    .post<void, AxiosResponse<Project<string>>>(`/project/`, formatProjectToCreate(project))
    .then((response) => mapProject(response.data));

const deleteProject = (customerName: string, siteId: string): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<Project<string>>>(`/project/${customerName}/${siteId}`)
    .then(() => undefined);

const loadProjectLevelStatuses = (): Promise<ProjectLevelStatus[]> =>
  apiClient
    .get<void, AxiosResponse<ProjectLevelStatus<string>[]>>(`project/project-level-statuses`)
    .then((response) =>
      response.data.map((projectLevelStatus) => ({
        ...projectLevelStatus,
        updated: parseISO(projectLevelStatus.updated),
      })),
    );

const recalculateProjectLevelStatuses = (): Promise<void> =>
  apiClient
    .post<void, AxiosResponse<void>>(`/project/project-level-statuses/recalculate`)
    .then((response) => response.data);

export default {
  loadAllProjects,
  loadProject,
  updateProject,
  createProject,
  deleteProject,
  loadProjectLevelStatuses,
  recalculateProjectLevelStatuses,
};
