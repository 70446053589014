<template>
  <div
    class="flex justify-center items-center h-screen"
    v-if="!authResolved && !$store.state.isSessionExpired"
  >
    <LoadingSpinner />
  </div>
  <div
    id="wrapper"
    class="h-screen overflow-auto"
    v-if="authResolved && !$store.state.isSessionExpired"
  >
    <router-view :key="route.path"></router-view>
  </div>
  <ConfirmationModal />
  <SessionExpired v-if="$store.state.isSessionExpired" />
  <div id="tooltip"></div>
</template>

<script lang="ts">
import "@vuepic/vue-datepicker/dist/main.css";
import "video.js/dist/video-js.css";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SessionExpired from "@/components/other/SessionExpired.vue";
import AuthMixins from "@/mixins/AuthMixins";
import logger from "@/services/logger";
import "@/styles/app.css";
import "@/styles/custom.css";
import "@/styles/tooltip.css";

export default defineComponent({
  name: "App",
  components: { SessionExpired, ConfirmationModal, LoadingSpinner },
  data() {
    return {
      authResolved: false as boolean,
    };
  },
  mixins: [AuthMixins],
  async mounted() {
    let session: unknown | undefined = undefined;
    try {
      session = await this.refreshSession();
    } catch (error) {
      logger.error("Unable to refresh session", error as Error);
    } finally {
      if (!session) {
        await this.router.isReady();
        if (this.route.meta.requireLogin) {
          this.router.replace("/log-in");
        }
      }
      setTimeout(() => {
        this.authResolved = true;
      }, 0);
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return { router, route };
  },
});
</script>
