<template>
  <ModalBasic :open="open" width="full" maxHeight="95vh" @onCloseModal="emit('closeModal')">
    <template #title>
      <div class="flex items-center px-2">
        <div class="flex">
          <div class="inline-flex">Reinitialization Planner Actuals</div>
          <router-link
            class="pl-2 cursor-pointer"
            :to="{
              name: 'Planner',
              params: {
                customer_name: customerName,
                site_id: siteId,
              },
              query: {
                mode: 'tracker',
              },
            }"
            target="_blank"
          >
            <ArrowTopRightOnSquareIcon class="w-5 h-5" />
          </router-link>
        </div>
        <LoadingSpinner v-if="loading" class="ml-2" size="h-6 w-6" />
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-500" />
    </template>
    <template #content-full>
      <div class="ml-2">
        <div
          class="p-16"
          v-if="
            !actualEventChanges ||
            (actualEventChanges &&
              actualEventChanges.missing_events.length === 0 &&
              actualEventChanges.inactive_events.length === 0)
          "
        >
          Everything up to date, nothing to reinitialize! ✅
        </div>
        <div v-else>
          <div
            v-if="actualEventChanges && actualEventChanges.missing_events.length > 0"
            class="py-2 text-gray-700"
          >
            <div class="font-semibold mb-2">Add missing events</div>
            <div class="-my-2 overflow-x-auto">
              <div class="inline-block min-w-full py-2 align-middle">
                <table class="min-w-full">
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2"></th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Plan-id</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Name</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Source-id</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Time range (processes)</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Active/completed</th>
                  <tbody class="divide-y divide-gray-200 bg-green-50">
                    <tr v-for="change in actualEventChanges.missing_events" :key="change._id">
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        <input
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-600"
                          v-model="change.selected"
                        />
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{ change.order + 1 }}
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{ change.name }}
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{ change.source_id }}
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{
                          `${formatDate(change.first_process_start)} → ${
                            change.end ? formatDate(change.end) : "today"
                          }`
                        }}
                        <div>
                          <span
                            class="underline hover:text-yellow-700 cursor-pointer"
                            @click="
                              (change.end = change.last_process_end), (change.isCompleted = true)
                            "
                            >last process</span
                          >
                          <span
                            class="ml-2 underline hover:text-yellow-700 cursor-pointer"
                            v-if="change.last_concrete_process_end"
                            @click="
                              (change.end = change.last_concrete_process_end),
                                (change.isCompleted = true)
                            "
                            >last concreting</span
                          >
                        </div>
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        <OaiSwitch
                          v-model="change.isCompleted"
                          @changed="
                            change.end = !change.isCompleted
                              ? null
                              : change.last_concrete_process_end
                              ? change.last_concrete_process_end
                              : change.last_process_end
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            v-if="actualEventChanges && actualEventChanges.inactive_events.length > 0"
            class="py-2 text-gray-700"
          >
            <div class="flex items-center mb-2">
              <div class="font-semibold">Remove inactive events</div>
              <div class="ml-2 mt-1 text-xs">(no mapped processes)</div>
            </div>

            <div class="-my-2 overflow-x-auto">
              <div class="inline-block min-w-full py-2 align-middle">
                <table class="min-w-full">
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2"></th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Plan-id</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Name</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Source-id</th>
                  <th class="whitespace-nowrap py-2 pl-4 text-sm ml-2">Time range (actual)</th>
                  <tbody class="divide-y divide-gray-200 bg-red-100">
                    <tr v-for="change in actualEventChanges.inactive_events" :key="change._id">
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        <input
                          type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-600"
                          v-model="change.selected"
                        />
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{ change.order !== null ? change.order + 1 : "-" }}
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        <div v-if="change.name">{{ change.name }}</div>
                        <div v-else class="text-red-800">Plan Item Missing.</div>
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{ change.source_id }}
                      </td>
                      <td class="whitespace-nowrap py-2 pl-4 text-sm ml-2">
                        {{
                          `${formatDate(change.start)} → ${
                            change.end ? formatDate(change.end) : "today"
                          }`
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="mt-6 inline-flex py-2 px-4 items-center rounded-md text-sm font-medium text-white shadow-sm bg-yellow-400 hover:bg-yellow-600"
            :disabled="loading"
            @click="applyReinitializePlannerActuals()"
          >
            <span>Apply Changes</span>
          </button>
        </div>
      </div>
    </template>
  </ModalBasic>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import { format, set } from "date-fns";
import { OaiSwitch } from "oai-components";
import {
  ActualEventChanges,
  ReinitializeActualEventsResponseItem,
  useCurrentCustomerName,
  useCurrentSiteId,
} from "oai-services";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalBasic from "@/components/modals/ModalBasic.vue";
import { useCustomToast } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";

const emit = defineEmits(["closeModal"]);

defineProps<{ open: boolean }>();

const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const route = useRoute();
const toast = useCustomToast();

const loading = ref<boolean>(false);
const actualEventChanges = ref<ReinitializeActualEventsResponseItem | null>(null);

const loadActualEventChanges = () => {
  loading.value = true;
  const { customer_name, site_id } = route.params;
  PlannerRepository.loadReinitializeActualEventChanges(customer_name as string, site_id as string)
    .then(
      (data) =>
        (actualEventChanges.value = {
          inactive_events: data.inactive_events.map((item) => ({
            ...item,
            selected: false,
          })),
          missing_events: data.missing_events.map((item) => ({
            ...item,
            selected: false,
            isCompleted: false,
            end: null,
          })),
        }),
    )
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const applyReinitializePlannerActuals = () => {
  if (!actualEventChanges.value) {
    return;
  }
  const changes: ActualEventChanges = {
    added: actualEventChanges.value.missing_events
      .filter((item) => item.selected)
      .map((item) => {
        return {
          _id: item._id,
          source_id: item.source_id,
          start: set(item.first_process_start, { hours: 7, minutes: 0, milliseconds: 0 }),
          end: item.end ? set(item.end, { hours: 17, minutes: 0, milliseconds: 0 }) : null,
        };
      }),
    modified: [],
    removed: actualEventChanges.value.inactive_events
      .filter((item) => item.selected)
      .map((item) => {
        return {
          _id: item._id,
        };
      }),
  };

  const { customer_name, site_id } = route.params;
  PlannerRepository.saveActualEventChanges(customer_name as string, site_id as string, changes)
    .then(() => {
      loadActualEventChanges();
    })
    .catch((error) => {
      logger.error(error);
      toast.error("Failed saving actual event reinitialization changes.");
    });
};

const formatDate = (date: Date) => {
  return format(date, "dd.MM.yyyy");
};

onMounted(() => {
  loadActualEventChanges();
});
</script>
