import { useQuery } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { getProcessClasses, ProcessClass, ShortenedProcessWithTags } from "oai-services";
import { computed, Ref } from "vue";
import { useStore } from "vuex";
import { useCurrentProject } from "@/composables/project";
import { useCustomToast } from "@/composables/toast";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import logger from "@/services/logger";
import { Project } from "@/types/Project";

export const useProcessClasses = (customerName?: string, siteId?: string) => {
  const store = useStore();
  const currentProject = useCurrentProject();

  return computed(() => {
    const project =
      customerName && siteId
        ? ((store.state.projects as Project[] | undefined)?.find(
            (project) => project.customer_name === customerName && project.site_id === siteId,
          ) as Project)
        : currentProject;
    let processGroups = null;
    if (project) {
      processGroups = project.process_groups;
    }
    return getProcessClasses(processGroups) as ProcessClass[];
  });
};

export const useShortenedProcessesWithTags = (customerName: string, siteId: string) => {
  const {
    data: shortenedProcessesWithTags,
    isLoading,
    error,
  } = useQuery<ShortenedProcessWithTags[] | undefined>({
    queryKey: ["shortened-processes-with-tags", customerName, siteId],
    queryFn: () => OpsProcessesRepository.loadShortenedProcessesWithTags(customerName, siteId),
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error("Unable to load shortened processes with tags");
      }
      return false;
    },
  });

  return { shortenedProcessesWithTags, isLoading, error };
};

export const useProcessVideoUrl = (
  customerName: string,
  siteId: string,
  processDbId: Ref<string | undefined>,
) => {
  const {
    data: processVideoUrl,
    isLoading,
    error,
  } = useQuery<string>({
    queryKey: ["process-video-url", customerName, siteId, processDbId],
    queryFn: async () => {
      if (!processDbId.value) {
        return "";
      }
      const { url } = await OpsProcessesRepository.loadProcessVideoUrl(
        customerName,
        siteId,
        processDbId.value,
      );
      return url;
    },
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error("Unable to load process video url");
      }
      return false;
    },
    staleTime: 2 * 60 * 60,
  });

  return { processVideoUrl, isLoading, error };
};
