<template>
  <div class="flex-1 flex gap-2 items-center min-w-0">
    <div class="w-7 h-7 shrink-0">
      <ChevronLeftIcon
        class="w-full h-full cursor-pointer"
        @click="goToPreviousProcess"
        v-if="currentProcessIndex > 0"
      />
    </div>
    <div class="flex-1 flex flex-col min-w-0">
      <OculaiVideoPlayer
        :loading="isProcessVideoUrlLoading || relevantProcesses.length === 0"
        :src="processVideoUrl"
        noSrcMessage="Video not found"
      />
      <div class="text-sm flex gap-1 whitespace-nowrap">
        <span>&nbsp;</span>
        <RouterLink
          class="flex-1 truncate font-semibold"
          v-if="currentProcess"
          :to="{
            name: 'ValidationPrdDate',
            params: {
              customer_name: customerName,
              site_id: siteId,
              date: currentProcess.date,
            },
            query: {
              camera_id: currentProcess.camera_id,
              process_id: currentProcess._id,
            },
          }"
          target="_blank"
        >
          {{ currentProcessIndex + 1 }}/{{ relevantProcesses.length }}:
          {{
            processClassesByEncodedLabel[currentProcess.encoded_label]?.decodedLabel ||
            currentProcess.encoded_label
          }}
        </RouterLink>
        <div class="flex gap-2" v-if="currentProcess">
          <div>{{ formatProcessDate(currentProcess.date) }}</div>
          <div>
            {{ formatIsoHourMinute(currentProcess.start_time) }}-{{
              formatIsoHourMinute(currentProcess.end_time)
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-7 h-7 shrink-0">
      <ChevronRightIcon
        class="w-full h-full cursor-pointer"
        @click="goToNextProcess"
        v-if="currentProcessIndex < relevantProcesses.length - 1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { format, parse } from "date-fns";
import { ProcessClass, ShortenedProcessWithTags } from "oai-services";
import { computed } from "vue";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import { useProcessClasses, useProcessVideoUrl } from "@/composables/process";

const props = defineProps<{
  customerName: string;
  siteId: string;
  sourceId: string;
  relevantProcesses: ShortenedProcessWithTags[];
  currentProcessIndex: number;
}>();

const emit = defineEmits<{ (eventName: "setCurrentProcessIndex", payload: number): void }>();

const currentProcess = computed(() => props.relevantProcesses[props.currentProcessIndex]);

const currentProcessId = computed(() => currentProcess.value?._id);

const processClasses = useProcessClasses(props.customerName, props.siteId);

const processClassesByEncodedLabel = computed(() =>
  processClasses.value.reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass;
    return acc;
  }, {} as Record<string, ProcessClass>),
);

const formatProcessDate = (dateText: string) =>
  format(parse(dateText, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");

const formatIsoHourMinute = (date: Date) => format(date, "HH:mm");

const { processVideoUrl, isLoading: isProcessVideoUrlLoading } = useProcessVideoUrl(
  props.customerName,
  props.siteId,
  currentProcessId,
);

const goToNextProcess = () => {
  emit("setCurrentProcessIndex", props.currentProcessIndex + 1);
};

const goToPreviousProcess = () => {
  emit("setCurrentProcessIndex", props.currentProcessIndex - 1);
};
</script>
