import { AxiosResponse } from "axios";
import { OpsProcessesRepository } from "oai-services";
import { apiClient } from "./clients";

const loadProcessVideoUrl = (
  customerName: string,
  siteId: string,
  processDbId: string,
): Promise<{ url: string }> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(
      `/ops-process/process-video/${customerName}/${siteId}/${processDbId}/no-rtp`,
    )
    .then((response) => response.data);

export default {
  ...OpsProcessesRepository(apiClient),
  loadProcessVideoUrl,
};
