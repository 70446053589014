<template>
  <MainLayout activeItem="GlobalProcessValidation">
    <div class="flex flex-col bg-gray-50 px-4 py-4 sm:px-6 gap-4">
      <h1 class="text-xl lg:text-3xl font-extrabold text-gray-900">Process validation</h1>
      <div class="flex flex-col gap-2 lg:gap-4 lg:items-center lg:flex-row">
        <GlobalProcessValidationFilterBar
          :filters="filters"
          @changed="handleFiltersChange"
          class="flex-1"
        />
        <button
          type="button"
          class="inline-flex gap-2 justify-center items-center rounded-md bg-yellow-500 px-4 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 h-9"
          v-if="filters.customerName && filters.siteId && filters.startDate && filters.endDate"
          :disabled="isExportLoading"
          @click="exportProcessDataXlsx"
        >
          <LoadingSpinner :cls="'text-white'" size="h-5 w-5" v-if="isExportLoading" />
          <span>Export</span>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-center h-full" v-if="loading">
      <LoadingSpinner />
    </div>
    <div class="flex items-center justify-center h-full text-red text-center" v-if="error">
      Unable to load<br />{{ error }}
    </div>
    <div v-if="!loading && !error">
      <div
        v-if="processes.length === 0"
        class="flex items-center justify-center container-height relative overflow-hidden"
      >
        <div
          v-if="route.query.filter_mappingState === 'conflicting'"
          class="absolute"
          style="margin-bottom: 150px"
        >
          <ConfettiExplosion :particleCount="200" :force="0.3" />
        </div>
        <div v-if="route.query.filter_mappingState === 'conflicting'">No unmapped processes</div>
        <div v-else-if="route.query.filter_mappingState === 'ignored'">No ignored processes</div>
        <div v-else>No processes</div>
      </div>
      <div v-if="processes.length > 0" class="flex flex-col container-height md:flex-row">
        <div class="overflow-y-scroll flex-1 table-height">
          <table class="w-full">
            <thead>
              <tr>
                <th class="text-left text-sm py-2 pl-4 bg-gray-100">#</th>
                <th class="text-left text-sm py-2 pl-4 bg-gray-100">process</th>
                <th class="text-left text-sm p-2 bg-gray-100">
                  <span class="flex items-center gap-2">
                    date
                    <ArrowsUpDownIcon
                      class="w-4 h-4 cursor-pointer"
                      v-if="sortBy"
                      @click="setSortBy(undefined)"
                    />
                  </span>
                </th>
                <th class="text-left text-sm p-2 bg-gray-100">
                  <span class="flex items-center gap-2">
                    customer
                    <ArrowsUpDownIcon
                      class="w-4 h-4 cursor-pointer"
                      v-if="!sortBy"
                      @click="setSortBy('customer')"
                    />
                  </span>
                </th>
                <th class="text-left text-sm p-2 bg-gray-100">state</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white overflow-scroll-y">
              <tr
                v-for="(process, index) in processes"
                :key="process._id"
                @click="selectProcessWithConfirmation(process)"
                class="cursor-pointer"
                :class="
                  selectedProcess === process
                    ? operationLoading
                      ? ['bg-yellow-200']
                      : ['bg-yellow-200 hover:bg-yellow-300']
                    : operationLoading
                    ? []
                    : ['hover:bg-yellow-100']
                "
                :id="process._id"
              >
                <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-500 ml-2">
                  {{ index + 1 }}
                </td>
                <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                  {{ getDecodedLabel(process.encoded_label) }}
                </td>
                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                  {{ formatProcessDate(process.date) }}
                </td>
                <td class="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                  {{ process.customer_name }} / {{ process.site_id }} / {{ process.camera_id }}
                </td>
                <td
                  class="px-2 py-2 text-sm text-gray-500 whitespace-nowrap flex gap-2 items-center"
                >
                  <span
                    v-if="getProcessState(process) === 'mapped'"
                    class="inline-flex bg-green-200 text-white rounded px-1 h-fit"
                    style="font-size: 10px"
                  >
                    Mapped
                  </span>
                  <span
                    v-if="getProcessState(process) === 'not_mapped'"
                    class="inline-flex bg-yellow-400 text-white rounded px-1 h-fit"
                    style="font-size: 10px"
                  >
                    Not mapped
                  </span>
                  <span
                    v-if="getProcessState(process) === 'ignored'"
                    class="inline-flex bg-gray-300 text-white rounded px-1 h-fit"
                    style="font-size: 10px"
                  >
                    Ignored
                  </span>
                  <CheckIcon
                    class="w-4 h-4 text-green-300"
                    v-if="
                      process.work_intervals.every(
                        (workInterval) => workInterval.workforce.validated_count !== null,
                      )
                    "
                    title="People count filled"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-2 mb-5 text-center text-sm text-gray-600">
            <div
              class="cursor-pointer"
              @click="handleLoadMore"
              v-if="
                processes.length % pageSize === 0 &&
                !loadingMore &&
                (lastLoadingMoreSize === null || lastLoadingMoreSize > 0)
              "
            >
              load more
            </div>
            <div v-if="loadingMore">loading...</div>
            <div v-if="errorLoadingMore">loading more failed</div>
          </div>
        </div>
        <div
          class="flex-1 border-t md:border-l p-5 pb-24 overflow-y-scroll relative flex gap-4 flex-col"
          v-if="selectedProcess"
        >
          <XMarkIcon
            class="h-6 w-6 absolute top-5 right-5 cursor-pointer"
            @click="selectProcessWithConfirmation(undefined)"
          />
          <div class="flex gap-4 flex-col">
            <div class="flex flex-col">
              <div class="flex gap-2 items-center pr-10">
                <span class="text-gray-500"> #{{ selectedProcessId + 1 }} </span>
                <span class="text-gray-700 font-bold">
                  {{ getDecodedLabel(selectedProcess.encoded_label) }}</span
                >
                <span
                  v-if="getProcessState(selectedProcess) === 'mapped'"
                  class="inline-flex bg-green-200 text-white rounded px-2 py-1 h-fit text-xs"
                >
                  Mapped
                </span>
                <span
                  v-if="getProcessState(selectedProcess) === 'not_mapped'"
                  class="inline-flex bg-yellow-400 text-white rounded px-2 py-1 h-fit text-xs"
                >
                  Not mapped
                </span>
                <span
                  v-if="getProcessState(selectedProcess) === 'ignored'"
                  class="inline-flex bg-gray-300 text-white rounded px-2 py-1 h-fit text-xs"
                >
                  Ignored
                </span>
              </div>
              <div class="text-gray-500 text-sm">
                {{ selectedProcess.customer_name }} / {{ selectedProcess.site_id }} /
                {{ selectedProcess.camera_id }}
              </div>
            </div>
            <div class="flex gap-1 text-sm text-gray-700 justify-between">
              <div class="flex gap-3">
                <div
                  class="flex gap-1 items-center select-none"
                  :class="operationLoading ? ['text-gray-300'] : ['cursor-pointer']"
                  v-if="!selectedProcess.planner_item_mapping?.ignored"
                  @click="setIgnore(true)"
                >
                  <ArchiveBoxArrowDownIcon class="w-5 h-5" />
                  Ignore
                </div>
                <div
                  class="flex gap-1 items-center whitespace-nowrap select-none"
                  :class="operationLoading ? ['text-gray-300'] : ['cursor-pointer']"
                  v-if="selectedProcess.planner_item_mapping?.ignored"
                  @click="setIgnore(false)"
                >
                  <ArrowUturnLeftIcon class="w-5 h-5" />
                  Don't ignore
                </div>
                <div
                  class="flex gap-1 items-center select-none"
                  :class="operationLoading ? ['text-gray-300'] : ['cursor-pointer']"
                  @click="deleteProcess()"
                >
                  <TrashIcon class="w-5 h-5" />
                  Delete
                </div>
              </div>
              <div class="flex gap-3">
                <div
                  v-if="!showDailyTimelapse"
                  @click="showDailyTimelapse = true"
                  class="cursor-pointer flex items-center gap-1"
                >
                  <PlayCircleIcon class="w-5 h-5" />
                  Daily Timelapse
                </div>
                <div
                  v-if="showDailyTimelapse"
                  @click="showDailyTimelapse = false"
                  class="cursor-pointer flex items-center gap-1"
                >
                  <AdjustmentsVerticalIcon class="w-5 h-5" />
                  Process Video
                </div>
                <router-link
                  :to="{
                    name: 'ValidationPrdDate',
                    params: {
                      customer_name: selectedProcess.customer_name,
                      site_id: selectedProcess.site_id,
                      date: selectedProcess.date,
                    },
                    query: {
                      camera_id: selectedProcess.camera_id,
                      process_id: selectedProcess._id,
                    },
                  }"
                  target="_blank"
                  class="inline-flex items-center gap-1"
                >
                  <ArrowTopRightOnSquareIcon class="w-5 h-5" />
                  Validation
                </router-link>
                <router-link
                  :to="{
                    name: 'PlanHierarchy',
                    params: {
                      customer_name: selectedProcess.customer_name,
                      site_id: selectedProcess.site_id,
                    },
                    query: {
                      section_mask_id: selectedProcess.section_mask_mapping.id,
                    },
                  }"
                  target="_blank"
                  class="inline-flex items-center gap-1"
                >
                  <ArrowTopRightOnSquareIcon class="w-5 h-5" />
                  Hierarchy
                </router-link>
              </div>
            </div>
          </div>
          <OculaiVideoPlayer
            :loading="showDailyTimelapse ? dailyTimelapseVideoLoading : videoUrlLoading"
            :src="
              showDailyTimelapse
                ? dailyTimelapseUrls[selectedProcess._id]
                : videoUrls[selectedProcess._id]
            "
            noSrcMessage="Video not found"
          />
          <div class="flex text-gray-700 gap-3 items-center">
            <span class="flex-1">
              <div v-if="editedOrSelectedProcess?.section_mask_mapping.level_id">
                <span class="font-semibold">
                  {{ getDisplayedLevelText(editedOrSelectedProcess.section_mask_mapping.level_id) }}
                </span>
                <span class="pl-2">
                  {{
                    editedOrSelectedProcess.section_mask_mapping.id &&
                    sectionMaskMap[editedOrSelectedProcess.section_mask_mapping.id]
                      ? getDisplayedSectionMaskText(
                          sectionMaskMap[editedOrSelectedProcess.section_mask_mapping.id],
                        )
                      : "(no section mapping)"
                  }}
                </span>
              </div>
            </span>
            <span class="whitespace-nowrap flex gap-2">
              <span>{{ formatProcessDate(selectedProcess.date) }}</span>
              <span>
                {{ formatIsoHourMinute(selectedProcess.start_time) }}-{{
                  formatIsoHourMinute(selectedProcess.end_time)
                }}
                {{
                  selectedProcess.breaks.length > 0
                    ? `(${selectedProcess.breaks.length} breaks)`
                    : ""
                }}
              </span>
            </span>
          </div>
          <table class="table-fixed">
            <thead>
              <tr>
                <td style="width: 30px"></td>
                <th class="text-left text-xs">Process class</th>
                <th class="text-left text-xs" style="width: 90px">Start</th>
                <th class="text-left text-xs" style="width: 90px">End</th>
                <th class="text-left text-xs" style="width: 110px">People</th>
                <th class="text-left text-xs" style="width: 110px">Location</th>
                <th style="width: 60px" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(workInterval, index) in editedOrSelectedProcess.work_intervals"
                :key="index"
              >
                <td class="align-top pt-2" style="width: 30px">
                  <ProcessWorkforceTooltip :workforce="workInterval.workforce" />
                </td>
                <td class="flex items-center pr-3">
                  <SearchList
                    cls="flex-1"
                    v-if="index === 0"
                    :defaultOptions="decodedProcessLabels"
                    :editMode="true"
                    :selectedValue="editedOrSelectedProcess.decoded_label as string"
                    @updateEvent="handleSelectProcessLabel($event)"
                    tagLabel="mappable"
                    :tagsFor="tagsFor"
                    :openMenuUpwards="true"
                  />
                  <span v-else class="text-xs flex-1 text-right"
                    >Work Interval #{{ index + 1 }}</span
                  >
                </td>
                <td class="align-top" style="width: 90px">
                  <SearchList
                    :defaultOptions="timeInputs"
                    :editMode="true"
                    :clock="true"
                    :selectedValue="formatIsoToMinuteHour(workInterval.start_time)"
                    @updateEvent="
                      setEditedWorkInterval(
                        {
                          start_time: setMinuteHourForIso($event),
                        },
                        index,
                      )
                    "
                    cls="w-20"
                    :openMenuUpwards="true"
                  />
                </td>
                <td class="align-top" style="width: 90px">
                  <SearchList
                    :defaultOptions="timeInputs"
                    :editMode="true"
                    :clock="true"
                    :selectedValue="formatIsoToMinuteHour(workInterval.end_time)"
                    @updateEvent="
                      setEditedWorkInterval(
                        {
                          end_time: setMinuteHourForIso($event),
                        },
                        index,
                      )
                    "
                    cls="w-20"
                    :openMenuUpwards="true"
                  />
                  <div
                    v-if="
                      workInterval.start_time &&
                      workInterval.end_time &&
                      workInterval.start_time >= workInterval.end_time
                    "
                    class="text-red text-xs"
                  >
                    end > start
                  </div>
                  <div
                    class="text-red text-xs"
                    v-if="
                      workInterval.start_time &&
                      workInterval.end_time &&
                      editedOrSelectedProcess.work_intervals.some(
                        (item, innerIndex) =>
                          index !== innerIndex &&
                          ((workInterval.start_time >= item.start_time &&
                            workInterval.start_time < item.end_time) ||
                            (workInterval.end_time <= item.end_time &&
                              workInterval.end_time > item.start_time)),
                      )
                    "
                  >
                    overlap
                  </div>
                </td>
                <td class="flex gap-2 items-center" style="width: 110px">
                  <input
                    type="number"
                    min="0"
                    :value="workInterval.workforce.validated_count"
                    @input="handlePeopleCountChange($event, index)"
                    @keypress="handlePeopleCountKeyPress"
                    title="People"
                    class="text-sm font-medium w-16 rounded-md border border-gray-300 shadow-sm focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500"
                  />
                  <PlusIcon
                    class="w-5 h-5 cursor-pointer text-gray-500"
                    @click="addWorkInterval"
                    v-if="index === 0"
                  />
                  <XMarkIcon
                    class="w-4 h-4 cursor-pointer text-gray-500"
                    @click="
                      setEditedProcess({
                        work_intervals: [
                          ...editedOrSelectedProcess.work_intervals.slice(0, index),
                          ...editedOrSelectedProcess.work_intervals.slice(index + 1),
                        ],
                      })
                    "
                    v-else
                  />
                </td>
                <td class="align-top" style="width: 110px">
                  <div
                    class="cursor-pointer text-xs truncate"
                    style="margin-top: 3px; width: 130px"
                    v-if="index === 0"
                    @click="openLocationMappingModal"
                  >
                    <div v-if="editedOrSelectedProcess?.section_mask_mapping.level_id">
                      <div class="w-32 font-semibold truncate">
                        {{
                          getDisplayedLevelText(
                            editedOrSelectedProcess.section_mask_mapping.level_id,
                          )
                        }}
                      </div>
                      <div
                        class="w-32 truncate"
                        v-if="
                          editedOrSelectedProcess.section_mask_mapping.id &&
                          sectionMaskMap[editedOrSelectedProcess.section_mask_mapping.id]
                        "
                      >
                        {{
                          getDisplayedSectionMaskText(
                            sectionMaskMap[editedOrSelectedProcess.section_mask_mapping.id],
                          )
                        }}
                      </div>
                      <div class="text-yellow-700" v-else>(no section mapping)</div>
                    </div>
                    <div v-else>-</div>
                  </div>
                </td>
                <td class="align-top" style="width: 60px">
                  <button
                    v-if="index === 0"
                    type="button"
                    class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm"
                    :class="
                      !saveButtonEnabled ? ['bg-gray-300'] : ['bg-green-400 hover:bg-green-600']
                    "
                    @click="save"
                    :disabled="!saveButtonEnabled"
                  >
                    <span class="pr-2" v-if="operationLoading == 'save'">
                      <LoadingSpinner :cls="'text-white'" size="h-5 w-5" />
                    </span>
                    <span>Save</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LocationMapping
      v-if="editedProcess"
      :key="editedProcess._id"
      :open="isLocationMappingModalOpen"
      :sectionMasks="filteredSectionMasks"
      :process="editedProcess"
      :tagMap="tagMap"
      :formatTagText="formatTagText"
      :getDisplayedSectionMaskText="getDisplayedSectionMaskText"
      currentMode="ops"
      @closeModal="isLocationMappingModalOpen = false"
      @updateProcess="
        updateProcessLocation($event);
        loadMappableEncodedLabels(true);
      "
    />
  </MainLayout>
</template>
<script lang="ts">
import { PlusIcon } from "@heroicons/vue/20/solid";
import {
  AdjustmentsVerticalIcon,
  ArchiveBoxArrowDownIcon,
  ArrowsUpDownIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
  PlayCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { format, isValid, parse, parseISO, setHours, setMinutes } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import deepEqual from "deep-equal";
import { DecodedLabel, EncodedLabel, ProcessWorkInterval } from "oai-services";
import { defineComponent } from "vue";
import ConfettiExplosion from "vue-confetti-explosion";
import { useRouter, useRoute } from "vue-router";
import * as XLSX from "xlsx";
import MainLayout from "@/components/layout/MainLayout.vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import SearchList from "@/components/other/SearchList.vue";
import { useProcessClasses } from "@/composables/process";
import { useProjects } from "@/composables/project";
import CameraRepository from "@/repositories/CameraRepository";
import ControllingRepository from "@/repositories/ControllingRepository";
import HierarchyRepository from "@/repositories/HierarchyRepository";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import SectionMasksRepository from "@/repositories/SectionMasksRepository";
import ValidationRepository from "@/repositories/ValidationRepository";
import logger from "@/services/logger";
import { timeInputs } from "@/services/timeDateFormat";
import { OutageExport, ProcessExport, SiteDurationExport } from "@/types/Controlling";
import { HierarchyTagStore, HierarchyType, SectionMask } from "@/types/HierarchyTag";
import { ProcessFilters } from "@/types/Process";
import { MappingProcess, OpsReviewDuration } from "@/types/Validation";
import GlobalProcessValidationFilterBar from "@/views/process_validation/components/GlobalProcessValidationFilterBar.vue";
import LocationMapping from "@/views/process_validation/components/LocationMapping.vue";
import ProcessWorkforceTooltip from "@/views/process_validation/components/ProcessWorkforceTooltip.vue";

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

export default defineComponent({
  name: "GlobalProcessValidation",
  components: {
    LocationMapping,
    CheckIcon,
    PlusIcon,
    ProcessWorkforceTooltip,
    ArchiveBoxArrowDownIcon,
    ArrowUturnLeftIcon,
    GlobalProcessValidationFilterBar,
    XMarkIcon,
    LoadingSpinner,
    MainLayout,
    OculaiVideoPlayer,
    SearchList,
    ArrowTopRightOnSquareIcon,
    ArrowsUpDownIcon,
    TrashIcon,
    PlayCircleIcon,
    AdjustmentsVerticalIcon,
    ConfettiExplosion,
  },
  data() {
    return {
      loading: true as boolean,
      operationLoading: null as string | null,
      error: null as null | Error,
      processes: [] as MappingProcess[],
      videoUrlLoading: false as boolean,
      videoUrls: {} as Record<string, string>,
      dailyTimelapseVideoLoading: false,
      dailyTimelapseUrls: {} as Record<string, string>,
      mappableEncodedLabels: {} as Record<string, number[]>,
      timeInputs: timeInputs,
      editedProcess: null as MappingProcess | null,
      isLocationMappingModalOpen: false,
      hierarchyTags: {} as Record<string, HierarchyTagStore[]>,
      sectionMasks: {} as Record<string, SectionMask[]>,
      showDailyTimelapse: false,
      loadingMore: false as boolean,
      errorLoadingMore: null as null | Error,
      lastLoadingMoreSize: null as number | null,
      pageSize: 1000,
      isExportLoading: false,
      startTimeEdit: null as Date | null,
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    const projects = useProjects();
    const route = useRoute();
    const router = useRouter();
    return {
      route,
      router,
      processClasses,
      projects,
    };
  },
  computed: {
    processEncodedLabels() {
      return this.processClasses.reduce((acc, process) => {
        acc[process.encodedLabel] = process.decodedLabel;
        return acc;
      }, {} as Record<string, DecodedLabel>);
    },
    processDecodedLabels() {
      return this.processClasses.reduce((acc, item) => {
        acc[item.decodedLabel] = item.encodedLabel;
        return acc;
      }, {} as Record<string, EncodedLabel>);
    },
    filters(): ProcessFilters {
      const getDate = (name: string) => {
        const dateText = this.route.query[name] as string | undefined;
        const parsedDate = dateText ? parse(dateText, "yyyy-MM-dd", new Date()) : undefined;
        return isValid(parsedDate) ? parsedDate : undefined;
      };
      return {
        customerName: this.route.query.filter_customerName as string | undefined,
        siteId: this.route.query.filter_siteId as string | undefined,
        cameraId: this.route.query.filter_cameraId as string | undefined,
        processClass: this.route.query.filter_processClass as string | undefined,
        startDate: getDate("filter_startDate"),
        endDate: getDate("filter_endDate"),
        building: this.route.query.filter_building as string | undefined,
        level: this.route.query.filter_level as string | undefined,
        section: this.route.query.filter_section as string | undefined,
        mappingState: this.route.query.filter_mappingState as "conflicting" | "ignored" | undefined,
      };
    },
    sortBy() {
      return this.route.query.sort_by as string | undefined;
    },
    tagsFor() {
      return (
        this.selectedProcess &&
        this.mappableEncodedLabels[this.selectedProcess._id]?.map(
          (encodedLabel) => this.processEncodedLabels[encodedLabel],
        )
      );
    },
    saveButtonEnabled() {
      return !this.operationLoading && this.hasProcessChange && this.areEditedWorkIntervalsValid;
    },
    hasProcessChange() {
      return (
        this.editedProcess &&
        this.selectedProcess &&
        this.editedProcess._id === this.selectedProcess._id &&
        !deepEqual(this.editedProcess, this.selectedProcess)
      );
    },
    selectedProcess() {
      return this.processes.find((process) => process._id === this.route.query.id);
    },
    selectedProcessId() {
      return this.processes.findIndex((process) => process === this.selectedProcess);
    },
    decodedProcessLabels() {
      const projectForProcess = this.projects.find(
        (project) =>
          this.selectedProcess?.customer_name === project.customer_name &&
          this.selectedProcess?.site_id === project.site_id,
      );
      return this.processClasses
        .filter((item) => projectForProcess?.process_groups.includes(item.group))
        .map((item) => item.decodedLabel)
        .sort((a, b) => a.localeCompare(b));
    },
    areEditedWorkIntervalsValid() {
      if (!this.editedProcess) {
        return false;
      }
      const sortedWorkIntervals = this.editedProcess.work_intervals
        .slice()
        .sort((a, b) => a.start_time.localeCompare(b.start_time));
      return sortedWorkIntervals.every(
        (workInterval, index) =>
          workInterval.start_time &&
          workInterval.end_time &&
          workInterval.start_time < workInterval.end_time &&
          (!sortedWorkIntervals[index - 1] ||
            workInterval.start_time >= sortedWorkIntervals[index - 1].end_time),
      );
    },
    editedOrSelectedProcess() {
      return (this.editedProcess || this.selectedProcess) as MappingProcess;
    },
    tagMap() {
      if (!this.selectedProcess) {
        return {} as Record<string, HierarchyTagStore>;
      }
      const key = `${this.selectedProcess.customer_name}_${this.selectedProcess.site_id}`;
      const hierarchyTags = this.hierarchyTags[key] || [];
      return hierarchyTags.reduce(
        (dict: Record<string, HierarchyTagStore>, item: HierarchyTagStore) => {
          dict[item._id] = item;

          if (item.splits) {
            item.splits.forEach((split) => {
              if (!split.id) {
                return;
              }

              dict[split.id] = {
                ...item,
                ...split,
                name: `${item.name} - ${split.name}`,
                _id: split.id,
                splits: null,
              };
            });
          }
          return dict;
        },
        {},
      );
    },
    sectionMaskMap() {
      return Object.values(this.sectionMasks)
        .flat()
        .reduce((dict: Record<string, SectionMask>, item: SectionMask) => {
          dict[item._id] = item;
          return dict;
        }, {});
    },
    filteredSectionMasks() {
      if (!this.selectedProcess) {
        return [];
      }
      const key = `${this.selectedProcess.customer_name}_${this.selectedProcess.site_id}`;
      const sectionMasks = this.sectionMasks[key] || [];
      return sectionMasks.filter((item) => item.camera_id === this.selectedProcess?.camera_id);
    },
  },
  mounted() {
    this.loadProcesses({
      resetSelected: false,
      sortBy: this.sortBy,
      filters: this.filters,
    });
    this.loadHierarchyData();
    this.loadSectionMasks();
  },
  methods: {
    loadProcesses({
      resetSelected,
      sortBy,
      filters,
    }: {
      resetSelected: boolean;
      sortBy: string | undefined;
      filters: ProcessFilters;
    }) {
      this.loading = true;
      this.error = null;
      return ValidationRepository.loadProcesses(filters, sortBy, this.pageSize, undefined)
        .then((response) => {
          this.processes = response.map((process) => this.addDecodedLabel(process));
          if (!this.selectedProcess || resetSelected) {
            this.selectProcess(this.processes[0]);
          }
        })
        .catch((error) => {
          logger.error(error);
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.scrollToSelectedProcess();
          }, 100);
        });
    },
    loadHierarchyData() {
      if (!this.selectedProcess) {
        return;
      }
      const key = `${this.selectedProcess.customer_name}_${this.selectedProcess.site_id}`;
      if (key in this.hierarchyTags) {
        return;
      }
      return HierarchyRepository.loadHierarchyData(
        this.selectedProcess.customer_name,
        this.selectedProcess.site_id,
      )
        .then((data) => {
          this.hierarchyTags[key] = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to retrieve hierarchy data");
          }
        });
    },
    loadSectionMasks() {
      if (!this.selectedProcess) {
        return;
      }
      const key = `${this.selectedProcess.customer_name}_${this.selectedProcess.site_id}`;
      if (key in this.sectionMasks) {
        return;
      }
      return SectionMasksRepository.loadValidSectionMasks(
        this.selectedProcess.customer_name,
        this.selectedProcess.site_id,
      )
        .then((data) => {
          this.sectionMasks[key] = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load section masks");
          }
        });
    },
    scrollToSelectedProcess() {
      if (this.selectedProcess) {
        document.getElementById(this.selectedProcess._id)?.scrollIntoView({ block: "center" });
      }
    },
    getDecodedLabel(encodedLabel: number | null) {
      return this.processClasses.find((item) => item.encodedLabel === encodedLabel)?.decodedLabel;
    },
    formatIsoHourMinute(isoString: string) {
      return format(parseUtcDate(isoString), "HH:mm");
    },
    formatProcessDate(dateText: string) {
      return format(parse(dateText, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");
    },
    selectProcessWithConfirmation(process: MappingProcess | undefined) {
      if (this.operationLoading) {
        return;
      }
      if (this.hasProcessChange) {
        if (!window.confirm("Unsaved change, continue?")) {
          return;
        }
        this.editedProcess = null;
      }
      this.selectProcess(process);
    },
    selectProcess(process: MappingProcess | undefined) {
      this.router.replace({ query: { ...this.route.query, id: process?._id } });
    },
    loadVideoUrl() {
      const process = this.selectedProcess;
      if (!process) {
        this.videoUrlLoading = false;
        return;
      }
      if (this.videoUrls[process._id]) {
        this.videoUrlLoading = false;
        return;
      }
      this.videoUrlLoading = true;
      OpsProcessesRepository.loadProcessVideoUrl(
        process.customer_name,
        process.site_id,
        process._id,
      )
        .then(async ({ url }) => {
          this.videoUrls[process._id] = url;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
          }
        })
        .finally(() => {
          if (this.selectedProcess === process) {
            this.videoUrlLoading = false;
          }
        });
    },
    loadDailyTimelapseUrl() {
      const process = this.selectedProcess;
      if (!process) {
        this.dailyTimelapseVideoLoading = false;
        return;
      }
      if (this.dailyTimelapseUrls[process._id]) {
        this.dailyTimelapseVideoLoading = false;
        return;
      }
      this.dailyTimelapseVideoLoading = true;
      CameraRepository.loadDailyTimelapseForCamera(
        process.customer_name,
        process.site_id,
        process.camera_id,
        process.date,
      )
        .then(async ({ url }) => {
          if (url) {
            this.dailyTimelapseUrls[process._id] = url;
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
          }
        })
        .finally(() => {
          if (this.selectedProcess === process) {
            this.dailyTimelapseVideoLoading = false;
          }
        });
    },
    loadProcessVideoOrDailyTimelapse() {
      if (this.showDailyTimelapse) {
        this.loadDailyTimelapseUrl();
      } else {
        this.loadVideoUrl();
      }
    },
    async loadMappableEncodedLabels(reload = false) {
      const process = this.editedProcess;
      if (!process || (!reload && this.mappableEncodedLabels[process._id])) {
        return;
      }
      ValidationRepository.loadMappableEncodedLabels(process._id, process.section_mask_mapping.id)
        .then((mappableEncodedLabels) => {
          this.mappableEncodedLabels[process._id] = mappableEncodedLabels;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    addDecodedLabel(process: MappingProcess) {
      return {
        ...process,
        decoded_label:
          process.encoded_label !== null
            ? this.processEncodedLabels[process.encoded_label]
            : ("" as DecodedLabel),
      };
    },
    async save() {
      if (!this.editedProcess || !this.selectedProcess) {
        return;
      }
      this.operationLoading = "save";
      const originalEditedProcess = this.editedProcess;

      const endTimeEdit = new Date();
      const opsReviewDuration = {
        start_time_edit: this.startTimeEdit
          ? this.startTimeEdit.toISOString().replace("Z", "+00:00")
          : null,
        end_time_edit: endTimeEdit ? endTimeEdit.toISOString().replace("Z", "+00:00") : null,
      } as OpsReviewDuration;

      ValidationRepository.updateProcess(
        this.selectedProcess.customer_name,
        this.selectedProcess.site_id,
        originalEditedProcess._id,
        this.editedProcess,
        opsReviewDuration,
      )
        .then((updatedProcess) => {
          this.handleUpdatedProcess(updatedProcess, originalEditedProcess);
        })
        .catch((error) => {
          logger.error(error);
          alert(`Saving failed: ${error.message}`);
        })
        .finally(() => {
          this.operationLoading = null;
          this.startTimeEdit = null;
        });
    },
    handleUpdatedProcess(updatedProcess: MappingProcess | null, originalProcess: MappingProcess) {
      let difference = 0;
      if (updatedProcess !== null) {
        const updatedState = this.getProcessState(updatedProcess);
        const originalState = this.getProcessState(originalProcess);

        if (originalState !== updatedState) {
          difference = updatedState === "mapped" ? -1 : updatedState === "not_mapped" ? 1 : 0;
        }
      } else {
        difference = -1;
      }

      if (difference !== 0 && this.$store.state.conflictingProcessesCount !== null) {
        this.updateConflictingProcessesCount(
          this.$store.state.conflictingProcessesCount + difference,
        );
      }

      if (!updatedProcess) {
        delete this.videoUrls[originalProcess._id];
        delete this.mappableEncodedLabels[originalProcess._id];
        const index = this.processes.findIndex((process) => process._id === originalProcess._id);
        if (index !== -1) {
          this.processes.splice(index, 1);
        }
        this.selectProcess(this.processes[index] || this.processes[this.processes.length - 1]);
        this.editedProcess = null;
        return;
      }
      this.videoUrls[updatedProcess._id] = this.videoUrls[originalProcess._id];
      // the original process is also kept in there, so that selectedProcess is never set to undefined
      // this would result e.g. in the video to play again
      this.processes = [
        ...this.processes.map((process) =>
          originalProcess._id === process._id ? this.addDecodedLabel(updatedProcess) : process,
        ),
        originalProcess,
      ];
      this.selectProcess(updatedProcess);
      setTimeout(() => {
        this.processes.splice(-1, 1);
      }, 0);
      if (!updatedProcess.planner_item_mapping.source_id) {
        this.showToast(
          "warning",
          "Process could not be mapped, check hierarchy or section mask config!",
        );
      }
      this.editedProcess = null;
    },
    setSortBy(sortBy: string | undefined) {
      this.router.replace({
        query: { ...this.route.query, sort_by: sortBy },
      });
      this.lastLoadingMoreSize = null;
      this.loadProcesses({ resetSelected: true, sortBy, filters: this.filters });
    },
    handleFiltersChange(filters: ProcessFilters) {
      this.router.replace({
        query: {
          ...this.route.query,
          filter_customerName: filters.customerName,
          filter_siteId: filters.siteId,
          filter_cameraId: filters.cameraId,
          filter_processClass: filters.processClass,
          filter_startDate: filters.startDate && format(filters.startDate, "yyyy-MM-dd"),
          filter_endDate: filters.endDate && format(filters.endDate, "yyyy-MM-dd"),
          filter_building: filters.building,
          filter_level: filters.level,
          filter_section: filters.section,
          filter_mappingState: filters.mappingState,
        },
      });
      this.lastLoadingMoreSize = null;
      this.loadProcesses({ resetSelected: true, sortBy: this.sortBy, filters });
    },
    setIgnore(ignored: boolean) {
      if (!this.selectedProcess || this.operationLoading) {
        return;
      }
      this.operationLoading = "ignore";
      const originalSelectedProcess = this.selectedProcess;
      ValidationRepository.ignoreProcess(originalSelectedProcess._id, ignored)
        .then((updatedProcess) => {
          this.handleUpdatedProcess(updatedProcess, originalSelectedProcess);
        })
        .catch((error) => {
          logger.error(error);
          alert(`Ignore failed: ${error.message}`);
        })
        .finally(() => {
          this.operationLoading = null;
        });
    },
    formatIsoToMinuteHour(isoString: string) {
      if (!isoString) {
        return "";
      }
      return format(parseUtcDate(isoString), "HH:mm");
    },
    setMinuteHourForIso(time: string) {
      if (!this.selectedProcess) {
        return;
      }
      const [hours, minutes] = time.split(":");
      const date = setMinutes(
        setHours(parse(this.selectedProcess.date, "yyyy-MM-dd", new Date()), parseInt(hours)),
        parseInt(minutes),
      );
      return isValid(date) ? formatUtcDate(date) : "";
    },
    setEditedProcess(partialProcess: Partial<MappingProcess>) {
      if (!this.selectedProcess) {
        return;
      }

      this.editedProcess = {
        ...this.selectedProcess,
        ...(this.editedProcess?._id === this.selectedProcess?._id ? this.editedProcess : {}),
        ...partialProcess,
      };
    },
    setEditedWorkInterval(partialWorkInterval: Partial<ProcessWorkInterval>, index: number) {
      this.setEditedProcess({
        work_intervals: [
          ...this.editedOrSelectedProcess.work_intervals.slice(0, index),
          {
            ...this.editedOrSelectedProcess.work_intervals[index],
            ...partialWorkInterval,
          },
          ...this.editedOrSelectedProcess.work_intervals.slice(index + 1),
        ],
      });
    },
    handlePeopleCountChange(e: Event, index: number) {
      if (!this.editedOrSelectedProcess) {
        return;
      }
      const value = (e.target as HTMLInputElement).value;
      this.setEditedWorkInterval(
        {
          workforce: {
            ...this.editedOrSelectedProcess.work_intervals[0].workforce,
            validated_count: value ? parseInt(value) : null,
          },
        },
        index,
      );
    },
    handlePeopleCountKeyPress(event: KeyboardEvent): void {
      if (!event.key.match(/^\d*$/)) {
        event.preventDefault();
      }
    },
    getProcessState(process: MappingProcess) {
      if (process.planner_item_mapping.source_id && !process.planner_item_mapping.ignored) {
        return "mapped";
      }
      if (!process.planner_item_mapping.source_id && !process.planner_item_mapping.ignored) {
        return "not_mapped";
      }
      return "ignored";
    },
    deleteProcess() {
      if (!this.selectedProcess || this.operationLoading) {
        return;
      }
      if (!window.confirm(`Are you sure to delete ${this.selectedProcess.decoded_label}?`)) {
        return;
      }
      this.operationLoading = "delete";
      const originalSelectedProcess = this.selectedProcess;
      ValidationRepository.deprecateProcess(originalSelectedProcess._id)
        .then(() => {
          this.handleUpdatedProcess(null, originalSelectedProcess);
        })
        .catch((error) => {
          logger.error(error);
          alert(`Delete failed: ${error.message}`);
        })
        .finally(() => {
          this.operationLoading = null;
        });
    },
    addWorkInterval() {
      this.setEditedProcess({
        work_intervals: [
          ...this.editedOrSelectedProcess.work_intervals,
          {
            start_time: "",
            end_time: "",
            workforce: {
              "55_percentile": null,
              "60_percentile": null,
              "65_percentile": null,
              "70_percentile": null,
              "75_percentile": null,
              "80_percentile": null,
              "85_percentile": null,
              "90_percentile": null,
              "95_percentile": null,
              median: null,
              mean: null,
              validated_count: null,
            },
          } as ProcessWorkInterval,
        ],
      });
    },
    openLocationMappingModal() {
      this.isLocationMappingModalOpen = true;
    },
    updateProcessLocation(processLocation: {
      maskId: string;
      levelId: string;
      bbox: [number, number][][];
    }) {
      if (!this.editedProcess || !processLocation.levelId || processLocation.bbox.length === 0) {
        return;
      }

      this.setEditedProcess({
        location: processLocation.bbox,
        section_mask_mapping: {
          id: processLocation.maskId,
          level_id: processLocation.levelId,
          building_name: this.selectedProcess?.section_mask_mapping.building_name,
          level_name: this.selectedProcess?.section_mask_mapping.level_name,
          section_name: this.selectedProcess?.section_mask_mapping.section_name,
        },
      });
    },
    formatTagText(tag: HierarchyTagStore) {
      return tag.name ? `${tag.name}` : `${this.capitalizeSectionType(tag.type)} (${tag.number})`;
    },
    getDisplayedSectionMaskText(sectionMask: SectionMask, renderLevelName = false) {
      const tags: HierarchyTagStore[] = [];
      // sectionMask.building_id && tags.push(this.tagMap[sectionMask.building_id]);
      // if (renderLevelName) {
      //   sectionMask.level_id && tags.push(this.tagMap[sectionMask.level_id]);
      // }
      // sectionMask.section_id && tags.push(this.tagMap[sectionMask.section_id]);

      if (sectionMask.building_id) {
        tags.push(this.tagMap[sectionMask.building_id]);
      }
      if (renderLevelName && sectionMask.level_id) {
        tags.push(this.tagMap[sectionMask.level_id]);
      }
      if (sectionMask.section_id) {
        tags.push(this.tagMap[sectionMask.section_id]);
      }

      const tagNames = tags.filter((tag) => tag).map((obj) => this.formatTagText(obj));
      return tagNames.join(" | ");
    },
    getDisplayedLevelText(levelId: string | null) {
      return levelId && levelId in this.tagMap ? this.formatTagText(this.tagMap[levelId]) : "";
    },
    capitalizeSectionType(name: HierarchyType) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    handleLoadMore() {
      if (this.loadingMore) {
        return;
      }
      this.loadingMore = true;
      this.errorLoadingMore = null;
      const skip = this.processes.length;
      return ValidationRepository.loadProcesses(this.filters, this.sortBy, this.pageSize, skip)
        .then((response) => {
          const mappedNewProcesses = response.map((process) => this.addDecodedLabel(process));
          this.lastLoadingMoreSize = mappedNewProcesses.length;
          if (mappedNewProcesses.length > 0) {
            this.processes = [...this.processes, ...mappedNewProcesses];
          }
        })
        .catch((error) => {
          logger.error(error);
          this.errorLoadingMore = error;
        })
        .finally(() => {
          this.loadingMore = false;
        });
    },
    exportProcessDataXlsx() {
      this.isExportLoading = true;

      const customerName = this.filters.customerName as string;
      const siteId = this.filters.siteId as string;
      const startDate = format(this.filters.startDate as Date, "yyyy-MM-dd");
      const endDate = format(this.filters.endDate as Date, "yyyy-MM-dd");

      return ControllingRepository.loadExportOpsReviewProcesses(
        customerName,
        siteId,
        startDate,
        endDate,
      )
        .then((data) => {
          const workbook = XLSX.utils.book_new();

          const worksheetDataProcesses = this.convertListToWorksheetData(data.processes);
          const worksheetDataSiteDuration = this.convertListToWorksheetData(data.site_duration);
          const worksheetDataOutages = this.convertListToWorksheetData(data.outages);

          if (worksheetDataProcesses) {
            const worksheetProcesses = XLSX.utils.aoa_to_sheet(worksheetDataProcesses);
            XLSX.utils.book_append_sheet(workbook, worksheetProcesses, "processes");
          }
          if (worksheetDataSiteDuration) {
            const worksheetSiteDuration = XLSX.utils.aoa_to_sheet(worksheetDataSiteDuration);
            XLSX.utils.book_append_sheet(workbook, worksheetSiteDuration, "site_duration");
          }
          if (worksheetDataOutages) {
            const worksheetOutages = XLSX.utils.aoa_to_sheet(worksheetDataOutages);
            XLSX.utils.book_append_sheet(workbook, worksheetOutages, "outages");
          }

          if (worksheetDataProcesses || worksheetDataSiteDuration || worksheetDataOutages) {
            XLSX.writeFile(
              workbook,
              `export_${customerName}_${siteId}_${startDate}-${endDate}.xlsx`,
            );
          } else {
            alert(
              `No data found for processes, outages and site duration between: ${startDate} - ${endDate}`,
            );
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load export data");
          }
        })
        .finally(() => {
          this.isExportLoading = false;
        });
    },
    convertListToWorksheetData(data: Array<ProcessExport | SiteDurationExport | OutageExport>) {
      if (data.length > 0) {
        const columnNames: Array<keyof (ProcessExport | SiteDurationExport | OutageExport)> =
          Object.keys(data[0]) as Array<keyof (ProcessExport | SiteDurationExport | OutageExport)>;

        const worksheetData = [];
        worksheetData.push(columnNames); // add column names as first row
        data.forEach((item: ProcessExport | SiteDurationExport | OutageExport) => {
          const row = columnNames.map((column) => item[column]);
          worksheetData.push(row);
        });
        return worksheetData;
      }
      return null;
    },
    updateConflictingProcessesCount(length: number) {
      this.$store.commit("setConflictingProcessesCount", length);
    },
    handleSelectProcessLabel(selectedValue: DecodedLabel) {
      const prevEncodedLabel = this.editedProcess?.encoded_label;
      const newEncodedLabel = this.processDecodedLabels[selectedValue];
      const tag = this.tagMap[this.editedProcess?.section_mask_mapping?.level_id as string];

      const updates: Record<string, unknown> = {
        decoded_label: selectedValue,
        encoded_label: newEncodedLabel,
      };

      if (prevEncodedLabel && tag) {
        const selectedTagSplit = tag?.splits?.find((split) =>
          split.processes.includes(prevEncodedLabel),
        );

        if (selectedTagSplit && !selectedTagSplit.processes.includes(newEncodedLabel)) {
          updates["section_mask_mapping"] = {
            id: null,
            level_id: null,
            level_name: undefined,
          };

          this.showToast(
            "warning",
            "Incompatible switch of process class (new class does not match previous split). Please reassign the the location.",
          );
        }
      }

      this.setEditedProcess(updates);
    },
  },
  watch: {
    selectedProcess(value) {
      this.setEditedProcess(value);
      this.loadProcessVideoOrDailyTimelapse();
      this.loadMappableEncodedLabels();
      this.loadHierarchyData();
      this.loadSectionMasks();
      this.startTimeEdit = new Date();
    },
    showDailyTimelapse() {
      this.loadProcessVideoOrDailyTimelapse();
    },
  },
});
</script>

<style scoped>
.container-height {
  height: calc(100vh - 88px);
}

@media (min-width: 1024px) {
  .container-height {
    height: calc(100vh - 122px);
  }
}

.table-height {
  max-height: calc(50vh - 122px);
}

@media (min-width: 768px) {
  .table-height {
    max-height: none;
  }
}

table td {
  border-style: hidden;
}
</style>
