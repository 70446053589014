import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { isAxiosError } from "axios";
import { ActualEventChanges, PlanConfig } from "oai-services";
import { useCustomToast } from "@/composables/toast";
import PlannerRepository from "@/repositories/PlannerRepository";
import SectionMasksRepository from "@/repositories/SectionMasksRepository";
import logger from "@/services/logger";
import { SectionMask } from "@/types/HierarchyTag";
import { ProjectPlannerStat, ReadyToCloseActualEvent } from "@/types/Planner";

export const useProjectPlannerStats = () => {
  const {
    data: projectPlannerStats,
    isLoading,
    error,
  } = useQuery<ProjectPlannerStat[] | undefined>({
    queryKey: ["project-planner-stats"],
    queryFn: () => PlannerRepository.loadProjectPlannerStats(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load project planner stats");
      return false;
    },
  });

  return { projectPlannerStats, isLoading, error };
};

export const useActualEventsReadyToClose = (customerName: string, siteId: string) => {
  const {
    data: actualEventsReadyToClose,
    isLoading,
    error,
  } = useQuery<ReadyToCloseActualEvent[] | undefined>({
    queryKey: ["actual-events-ready-to-close", customerName, siteId],
    queryFn: () => PlannerRepository.loadActualEventsReadyToClose(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load actual events ready to close");
      return false;
    },
  });

  return { actualEventsReadyToClose, isLoading, error };
};

export const usePlanConfig = (customerName: string, siteId: string) => {
  const {
    data: planConfig,
    isLoading,
    error,
  } = useQuery<PlanConfig | null>({
    queryKey: ["plan-config", customerName, siteId],
    queryFn: () => PlannerRepository.loadPlanConfig(customerName, siteId),
    useErrorBoundary: (error) => {
      if (!isAxiosError(error) || error?.response?.status !== 404) {
        logger.error(error);
        useCustomToast().error("Unable to load plan config");
      }
      return false;
    },
  });

  return { planConfig, isLoading, error };
};

export const useSectionMasks = (customerName: string, siteId: string) => {
  const {
    data: sectionMasks,
    isLoading,
    error,
  } = useQuery<SectionMask[]>({
    queryKey: ["section-masks", customerName, siteId],
    queryFn: () => SectionMasksRepository.loadSectionMasks(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load section masks");
      return false;
    },
  });

  return { sectionMasks, isLoading, error };
};

export const useUpdateActualEvents = (customerName: string, siteId: string) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateActualEvents,
    isLoading: isLoading,
    error,
  } = useMutation<
    {
      added_ids: {
        _id: string;
        db_id: string;
      }[];
    },
    Error,
    ActualEventChanges
  >({
    mutationFn: (changes) =>
      PlannerRepository.saveActualEventChanges(customerName, siteId, changes),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to save actual events");
      return false;
    },
    onSuccess: (_result, changes) => {
      const closedIds = new Set<string>([
        ...changes.modified.filter((change) => change.end).map((change) => change._id),
        ...changes.removed.map((change) => change._id),
      ]);
      queryClient.setQueryData(
        ["actual-events-ready-to-close", customerName, siteId],
        (actualEventsReadyToClose: ReadyToCloseActualEvent[] | undefined) =>
          actualEventsReadyToClose?.filter((event) => !closedIds.has(event._id)),
      );
      queryClient.setQueryData(
        ["project-planner-stats"],
        (stats: ProjectPlannerStat[] | undefined) =>
          stats?.map((stat) =>
            stat.customer_name === customerName && stat.site_id === siteId
              ? {
                  ...stat,
                  ready_to_close: stat.ready_to_close.filter((item) => !closedIds.has(item._id)),
                }
              : stat,
          ),
      );
    },
  });

  return { updateActualEvents, isLoading, error };
};

export const useDeferReadyToClose = (customerName: string, siteId: string) => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: deferReadyToClose,
    isLoading: isLoading,
    error,
  } = useMutation<ProjectPlannerStat, Error, string>({
    mutationFn: (actualEventId) =>
      PlannerRepository.deferReadyToClose(customerName, siteId, actualEventId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to defer actual event");
      return false;
    },
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["project-planner-stats"],
        (stats: ProjectPlannerStat[] | undefined) =>
          stats?.map((stat) => (stat._id === result._id ? result : stat)),
      );
    },
  });

  return { deferReadyToClose, isLoading, error };
};
