import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";
import { useCustomToast } from "@/composables/toast";
import CameraRepository from "@/repositories/CameraRepository";
import logger from "@/services/logger";

export const useMostRecentCameraImageUrl = (
  customerName: string,
  siteId: string,
  cameraId: Ref<string | null>,
) => {
  const {
    data: mostRecentCameraImageUrl,
    isLoading,
    error,
  } = useQuery<string>({
    queryKey: ["most-recent-camera-image-url", customerName, siteId, cameraId],
    queryFn: async () =>
      cameraId.value
        ? CameraRepository.loadMostRecentCameraImageUrl(customerName, siteId, cameraId.value)
        : "",
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load most recent camera image url");
      return false;
    },
    staleTime: 2 * 60 * 60,
  });

  return { mostRecentCameraImageUrl, isLoading, error };
};
