<template>
  <SlideOver :open="open" @closeSlideOver="$emit('closed')" customCls="sm:w-1/3">
    <template #title>Actuals Changelog</template>
    <template #content>
      <div v-if="!loaded" class="h-full flex items-center justify-center">
        <LoadingSpinner />
      </div>
      <div
        class="h-full flex items-center justify-center"
        v-if="loaded && actualEventsChanges.length === 0"
      >
        No changes yet
      </div>
      <div
        class="overflow-y-auto flex flex-col gap-2 text-left"
        v-if="loaded && actualEventsChanges.length > 0"
      >
        <div
          v-for="actualEventChange in actualEventsChanges"
          :key="actualEventChange._id"
          :class="[
            'flex flex-col gap-3 cursor-pointer hover:bg-gray-300 p-3',
            { 'bg-gray-300': actualEventChange.expanded },
          ]"
          @click="actualEventChange.expanded = !actualEventChange.expanded"
        >
          <div>
            <div class="flex justify-between gap-4 items-center">
              <span class="flex-1 truncate" :title="actualEventChange.created_by">
                <span
                  v-if="isNew(actualEventChange.created)"
                  class="bg-yellow-400 text-white px-1 py-0.5 text-xs"
                  >new</span
                >
                {{ actualEventChange.created_by }}
              </span>
              <span class="font-bold">{{ actualEventChange.changes.length }} changes</span>
              <ChevronDownIcon
                class="h-5 w-5"
                aria-hidden="true"
                v-if="!actualEventChange.expanded"
              />
              <ChevronUpIcon class="h-5 w-5" aria-hidden="true" v-if="actualEventChange.expanded" />
            </div>
            <div class="text-xs text-gray-500">
              {{ formatDate(actualEventChange.created) }}
            </div>
          </div>
          <div v-if="actualEventChange.expanded" class="flex flex-col gap-3">
            <div
              v-for="(change, index) in actualEventChange.changes"
              :key="index"
              class="flex flex-col gap-2 bg-gray-100 p-3"
            >
              <div class="flex gap-2">
                <span
                  :class="[
                    'text-white p-1 text-xs text-center rounded',
                    { 'bg-yellow-400': getChangeType(change) === 'modified' },
                    { 'bg-green-300': getChangeType(change) === 'added' },
                    { 'bg-red-400': getChangeType(change) === 'deleted' },
                  ]"
                  style="flex: 0 0 60px; margin-top: 1px; height: min-content"
                >
                  {{ getChangeLabel(change) }}
                </span>
                <span class="font-bold">
                  <PlannerItemName
                    :name="sourceIdsToNames[(change.new_event || change.old_event!).source_id]"
                  />
                </span>
              </div>
              <div v-if="getChangeType(change) === 'modified'">
                <ActualsChangelogEventItem :event="change.old_event!" />
                <div class="text-center">&darr;</div>
                <ActualsChangelogEventItem :event="change.new_event!" />
              </div>
              <ActualsChangelogEventItem
                :event="change.new_event!"
                v-if="getChangeType(change) === 'added'"
              />
            </div>
          </div>
        </div>
        <div
          v-if="actualEventsChanges.length >= 100"
          class="text-center text-xs text-gray-500 mt-5"
        >
          Note: Only 100 items are shown
        </div>
      </div>
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { parseISO, format, isBefore, sub } from "date-fns";
import { PlanMixins } from "oai-services";
import { ActualEventChange, ActualEventChangeItem, PlannerItem } from "oai-services";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import SlideOver from "@/components/other/SlideOver.vue";
import PlannerRepository from "@/repositories/PlannerRepository";
import PlannerItemName from "@/views/planner/components/PlannerItemName.vue";
import ActualsChangelogEventItem from "./ActualsChangelogEventItem.vue";

export default defineComponent({
  name: "ActualsChangelog",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
    plannerItems: {
      type: Array as PropType<PlannerItem[]>,
      required: false,
    },
  },
  emits: ["closed"],
  components: {
    LoadingSpinner,
    SlideOver,
    ChevronDownIcon,
    ChevronUpIcon,
    ActualsChangelogEventItem,
    PlannerItemName,
  },
  data() {
    return {
      actualEventsChanges: [] as (ActualEventChange & { expanded: boolean })[],
      sourceIdsToNames: {} as Record<string, string[]>,
      loaded: false,
    };
  },
  mounted() {
    if (this.plannerItems) {
      this.sourceIdsToNames = this.getSourceIdsToNames(this.plannerItems);
    }
    if (this.open) {
      this.loadActualEventChanges();
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.loadActualEventChanges();
      }
    },
    plannerItems(value) {
      if (value) {
        this.sourceIdsToNames = this.getSourceIdsToNames(value);
      }
    },
  },
  mixins: [PlanMixins],
  methods: {
    loadActualEventChanges() {
      this.loaded = false;
      PlannerRepository.loadActualEventsChanges(this.customerName, this.siteId)
        .then((actualEventsChanges) => {
          this.actualEventsChanges = actualEventsChanges.map((actualEventChange) => ({
            ...actualEventChange,
            expanded: false,
          }));
        })
        .catch(() => alert("Unable to load actuals changelog"))
        .finally(() => {
          this.loaded = true;
        });
    },
    isNew(dateString: string) {
      return isBefore(sub(new Date(), { minutes: 15 }), parseISO(dateString));
    },
    formatDate(date: string) {
      return format(parseISO(date), "dd.MM.yyyy HH:mm");
    },
    getChangeType(change: ActualEventChangeItem): "modified" | "deleted" | "added" {
      if (change.old_event && change.new_event) {
        return "modified";
      }
      if (change.old_event && !change.new_event) {
        return "deleted";
      }
      return "added";
    },
    getChangeLabel(change: ActualEventChangeItem) {
      return this.getChangeType(change);
    },
  },
});
</script>
