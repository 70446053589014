import { parseISO } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { apiClient } from "@/repositories/clients";
import { OpsReviewBatch, OpsReviewBatchToCreate } from "@/types/Validation";

const formatLocalDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "");

const mapOpsReviewBatch = (opsReviewBatch: OpsReviewBatch<string>): OpsReviewBatch => ({
  ...opsReviewBatch,
  start_date_local: parseISO(opsReviewBatch.start_date_local),
  end_date_local: parseISO(opsReviewBatch.end_date_local),
});

const formatOpsReviewBatchToCreate = (
  opsReviewBatchToCreate: OpsReviewBatchToCreate,
): OpsReviewBatchToCreate<string> => ({
  ...opsReviewBatchToCreate,
  start_date_local: formatLocalDate(opsReviewBatchToCreate.start_date_local),
  end_date_local: formatLocalDate(opsReviewBatchToCreate.end_date_local),
});

const loadOpsReviewBatches = () =>
  apiClient
    .get<OpsReviewBatch<string>[]>(`/prd-validation/ops-review-batches/`)
    .then((response) => response.data.map((opsReviewBatch) => mapOpsReviewBatch(opsReviewBatch)));

const createOpsReviewBatch = (opsReviewBatchToCreate: OpsReviewBatchToCreate) =>
  apiClient
    .post<OpsReviewBatch<string>>(
      `/prd-validation/ops-review-batches/`,
      formatOpsReviewBatchToCreate(opsReviewBatchToCreate),
    )
    .then((response) => mapOpsReviewBatch(response.data));

const deleteOpsReviewBatch = (batchId: string) =>
  apiClient
    .delete<void>(`/prd-validation/ops-review-batches/${batchId}`)
    .then((response) => response.data);

export default {
  loadOpsReviewBatches,
  createOpsReviewBatch,
  deleteOpsReviewBatch,
};
