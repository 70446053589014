import { AxiosResponse } from "axios";
import { apiClient } from "@/repositories/clients";
import { DatasetPersonImage, SingleImage, DailyTimelapseUrl, StreamOutage } from "@/types/Camera";

const deletePersonImageFromDatasetBuffer = (
  customerName: string,
  siteId: string,
  cameraId: string,
  timestamp: string,
) =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `/camera/dataset/person/image/${customerName}/${siteId}/${cameraId}/${timestamp}`,
    )
    .then((response) => response.data);

const createBatchFromPersonDatasetBuffer = () =>
  apiClient.post<void, AxiosResponse<void>>(`/camera/dataset/person/create-batch`);

const getPersonImagesDataset = () =>
  apiClient
    .get<void, AxiosResponse<DatasetPersonImage[]>>(`/camera/dataset/person`)
    .then((response) => response.data);

const loadSingleImage = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date?: string,
  hour?: string,
  checkRtp?: boolean,
): Promise<SingleImage | null> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);

    if (hour) {
      searchParams.set("hour", hour);
    }
  }

  if (checkRtp === false) {
    searchParams.set("check_rtp", "0");
  }
  return apiClient
    .get<void, AxiosResponse<SingleImage>>(
      `/camera/single-image/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });
};

const loadDailyTimelapseForCamera = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<DailyTimelapseUrl> =>
  apiClient
    .get<void, AxiosResponse<DailyTimelapseUrl>>(
      `/camera/daily-timelapse/camera/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => response.data);

const loadDailyTimelapseUrlsForDate = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<DailyTimelapseUrl[]> =>
  apiClient
    .get<void, AxiosResponse<DailyTimelapseUrl[]>>(
      `/camera/daily-timelapse/date/${customerName}/${siteId}/${date}`,
    )
    .then((response) => response.data);

const loadDailyOutages = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
): Promise<StreamOutage[] | null> =>
  apiClient
    .get<void, AxiosResponse<StreamOutage[]>>(
      `/camera/outages/${customerName}/${siteId}/${cameraId}/${date}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadMostRecentCameraImageUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<string> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(
      `/camera/gallery/most-recent-camera-image/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data.url);

export default {
  deletePersonImageFromDatasetBuffer,
  createBatchFromPersonDatasetBuffer,
  getPersonImagesDataset,
  loadSingleImage,
  loadDailyTimelapseForCamera,
  loadDailyTimelapseUrlsForDate,
  loadDailyOutages,
  loadMostRecentCameraImageUrl,
};
