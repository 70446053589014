<template>
  <SlideOver :open="open" @closeSlideOver="$emit('closed')" customCls="sm:w-1/3">
    <template #title>Revisions</template>
    <template #content>
      <div v-if="!loaded" class="h-full flex items-center justify-center">
        <LoadingSpinner />
      </div>
      <div class="h-full flex items-center justify-center" v-if="loaded && plans.length === 0">
        No revisions yet
      </div>
      <div class="overflow-y-auto flex flex-col gap-2 text-left" v-if="loaded && plans.length > 0">
        <div
          v-for="plan in plans"
          :key="plan._id"
          :class="['flex flex-row gap-4 p-3', { 'bg-yellow-200': currentPlanId === plan._id }]"
        >
          <div class="flex-1 min-w-0">
            <div class="font-bold truncate" :title="plan.name">
              <span
                v-if="plan.created && isNew(plan.created)"
                class="bg-yellow-400 text-white px-1 py-0.5 text-xs"
                >new</span
              >
              {{ plan.name }}
            </div>
            <div class="text-xm">{{ plan.planner_item_count }} planner items</div>
            <div class="flex justify-between gap-4 items-center">
              <span class="flex-1 truncate text-gray-500" :title="plan.created_by">
                {{ plan.created_by }}
              </span>
            </div>
            <FormattedDate :date-string="plan.created" cls="text-xs text-gray-500" />
          </div>
          <div class="flex gap-1" v-if="currentPlanId !== plan._id">
            <ArrowUpOnSquareIcon
              :class="[
                'h-5 w-5',
                {
                  'text-gray-400': isActionLoading,
                  'text-green-500': !isActionLoading,
                  'cursor-pointer': !isActionLoading,
                },
              ]"
              aria-hidden="true"
              title="Activate Revision"
              @click="!isActionLoading && activateRevision(plan)"
            />
            <TrashIcon
              :class="[
                'h-5 w-5',
                {
                  'text-gray-400': isActionLoading,
                  'text-red-500': !isActionLoading,
                  'cursor-pointer': !isActionLoading,
                },
              ]"
              aria-hidden="true"
              title="Delete Revision"
              @click="!isActionLoading && deleteRevision(plan)"
            />
          </div>
        </div>
        <div v-if="plans.length >= 100" class="text-center text-xs text-gray-500 mt-5">
          Note: Only 100 items are shown
        </div>
      </div>
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { ArrowUpOnSquareIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { isBefore, parseISO, sub } from "date-fns";
import { Plan, PlanWithPlannerItemCount } from "oai-services";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import FormattedDate from "@/components/other/FormattedDate.vue";
import SlideOver from "@/components/other/SlideOver.vue";
import PlannerRepository from "@/repositories/PlannerRepository";

export default defineComponent({
  name: "PlanRevisions",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
    currentPlanId: {
      type: String,
      required: false,
    },
  },
  emits: ["closed", "planActivationStarted", "planActivationFinished", "planActivationFailed"],
  components: {
    ArrowUpOnSquareIcon,
    LoadingSpinner,
    SlideOver,
    TrashIcon,
    FormattedDate,
  },
  data() {
    return {
      plans: [] as PlanWithPlannerItemCount[],
      loaded: false,
      isActionLoading: false,
    };
  },
  mounted() {
    if (this.open) {
      this.loadPlanRevisions();
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.loadPlanRevisions();
      }
    },
  },
  methods: {
    loadPlanRevisions() {
      this.loaded = false;
      PlannerRepository.loadPlanRevisions(this.customerName, this.siteId)
        .then((plans) => {
          this.plans = plans;
        })
        .catch(() => alert("Unable to load revisions"))
        .finally(() => {
          this.loaded = true;
        });
    },
    activateRevision(plan: Plan) {
      if (!window.confirm(`Are you sure to activate plan "${plan.name}"?`)) {
        return;
      }
      this.$emit("planActivationStarted");
      this.isActionLoading = true;
      const { customer_name, site_id } = this.route.params;

      PlannerRepository.activatePlan(customer_name as string, site_id as string, plan._id)
        .then((planConfig) => {
          this.$emit("planActivationFinished", planConfig);
        })
        .catch(() => {
          alert("Unable to activate revision");
          this.$emit("planActivationFailed");
        })
        .finally(() => {
          this.isActionLoading = false;
        });
    },
    deleteRevision(plan: Plan) {
      if (!window.confirm(`Are you sure to delete plan "${plan.name}"?`)) {
        return;
      }
      this.isActionLoading = true;
      const { customer_name, site_id } = this.route.params;
      PlannerRepository.deletePlanRevision(customer_name as string, site_id as string, plan._id)
        .then(() => {
          this.plans = this.plans.filter(({ _id }) => _id !== plan._id);
        })
        .catch(() => alert("Unable to delete revision"))
        .finally(() => {
          this.isActionLoading = false;
        });
    },
    isNew(dateString: string) {
      return isBefore(sub(new Date(), { minutes: 15 }), parseISO(dateString));
    },
  },
  setup() {
    const route = useRoute();
    return { route };
  },
});
</script>
