<template>
  <div class="flex-1 flex gap-2 items-center min-w-0">
    <div class="w-7 h-7 shrink-0">
      <ChevronLeftIcon
        class="w-full h-full cursor-pointer"
        @click="currentIndex -= 1"
        v-if="currentIndex > 0"
      />
    </div>
    <div class="flex flex-col flex-1">
      <div class="relative flex-1 bg-gray-100" :style="{ aspectRatio: '4/3' }">
        <canvas ref="canvasRef" class="w-full h-full" />
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" v-if="isLoading">
          <LoadingSpinner />
        </div>
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" v-if="imageError">
          Unable to load image
        </div>
      </div>
      <div class="text-sm font-semibold">most recent camera image</div>
    </div>
    <div class="w-7 h-7 shrink-0">
      <ChevronRightIcon
        class="w-full h-full cursor-pointer"
        @click="currentIndex += 1"
        v-if="currentIndex < cameraIds.length - 1"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { ShortenedProcessWithTags } from "oai-services";
import { computed, onMounted, ref, watch } from "vue";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import { useMostRecentCameraImageUrl } from "@/composables/camera";
import { useSectionMasks } from "@/composables/planner";
import { Drawing } from "@/services/drawing";
import logger from "@/services/logger";
import { HierarchyTagStore } from "@/types/HierarchyTag";

const props = defineProps<{
  customerName: string;
  siteId: string;
  sourceId: string;
  shortenedProcessesWithTags: ShortenedProcessWithTags[] | undefined;
  hierarchyTags: HierarchyTagStore[];
}>();

const canvasRef = ref<HTMLCanvasElement | null>(null);
const isImageLoading = ref(false);
const imageError = ref(false);

const cameraIds = computed(() => [
  ...new Set(
    (props.shortenedProcessesWithTags || [])
      .filter((process) => process.planner_item_mapping.source_id === props.sourceId)
      .map((process) => process.camera_id),
  ),
]);

const { sectionMasks, isLoading: areSectionMasksLoading } = useSectionMasks(
  props.customerName,
  props.siteId,
);

const getInitialIndex = () => cameraIds.value.length - 1;

const currentIndex = ref(getInitialIndex());

const cameraId = computed(() => cameraIds.value[currentIndex.value]);

const { mostRecentCameraImageUrl, isLoading: isMostRecentCameraImageUrlLoading } =
  useMostRecentCameraImageUrl(props.customerName, props.siteId, cameraId);

const isLoading = computed(
  () =>
    !props.shortenedProcessesWithTags ||
    props.hierarchyTags.length === 0 ||
    isMostRecentCameraImageUrlLoading.value ||
    isImageLoading.value ||
    areSectionMasksLoading.value,
);

const currentSectionMask = computed(() => {
  const buildingId =
    props.hierarchyTags.find(
      (tag) => tag.type === "building" && tag.source_ids.includes(props.sourceId),
    )?._id ?? null;
  const levelId =
    props.hierarchyTags.find(
      (tag) => tag.type === "level" && tag.source_ids.includes(props.sourceId),
    )?._id ?? null;
  const sectionId =
    props.hierarchyTags.find(
      (tag) => tag.type === "section" && tag.source_ids.includes(props.sourceId),
    )?._id ?? null;
  return sectionMasks.value?.find(
    (sectionMask) =>
      sectionMask.camera_id === cameraId.value &&
      sectionMask.building_id === buildingId &&
      sectionMask.level_id === levelId &&
      sectionMask.section_id === sectionId,
  );
});

const loadImage = async (url: string) => {
  try {
    imageError.value = false;
    isImageLoading.value = true;
    const image = new Image();
    image.src = url;
    await image.decode();
    return image;
  } catch (error) {
    logger.error(error);
    imageError.value = true;
    return null;
  } finally {
    isImageLoading.value = false;
  }
};

const createDrawing = async () => {
  if (
    !canvasRef.value ||
    !cameraId.value ||
    !mostRecentCameraImageUrl.value ||
    !currentSectionMask.value
  ) {
    return;
  }
  const sectionMaskId = currentSectionMask.value._id;
  const image = await loadImage(mostRecentCameraImageUrl.value);
  if (!image || !canvasRef.value || sectionMaskId !== currentSectionMask.value?._id) {
    return;
  }
  const sectionMasks = [{ ...currentSectionMask.value, show_on_canvas: true }];
  const tagMap = props.hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const drawing = new Drawing(
    canvasRef.value,
    image,
    sectionMasks,
    props.customerName,
    props.siteId,
    cameraId.value,
    tagMap,
    false,
  );
  drawing.renderAll();
};

watch([canvasRef, cameraId, mostRecentCameraImageUrl, currentSectionMask], () => {
  createDrawing();
});

watch(cameraIds, () => {
  currentIndex.value = getInitialIndex();
});

onMounted(() => {
  createDrawing();
});
</script>
