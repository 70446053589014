import { AxiosResponse } from "axios";
import { PlannerRepository } from "oai-services";
import { apiClient } from "@/repositories/clients";
import { HierarchyTagStore } from "@/types/HierarchyTag";
import { ProjectPlannerStat, ReadyToCloseActualEvent } from "@/types/Planner";
import { SectioningPdf } from "@/types/SectioningPdf";

const loadSectioningPdfs = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<SectioningPdf[]>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const createSectioningPdf = (customerName: string, siteId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return apiClient
    .post<void, AxiosResponse<SectioningPdf>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}`,
      formData,
    )
    .then((response) => response.data);
};

const deleteSectioningPdf = (customerName: string, siteId: string, _id: string) =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `planner-v2/sectioning-pdfs/${customerName}/${siteId}/${_id}`,
    )
    .then((response) => response.data);

const loadActualEventsReadyToClose = (
  customerName: string,
  siteId: string,
): Promise<ReadyToCloseActualEvent[]> =>
  apiClient
    .get<void, AxiosResponse<ReadyToCloseActualEvent[]>>(
      `/planner-v2/actual-events/ready-to-close/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const loadHierarchyTags = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<HierarchyTagStore[]>>(
      `/planner-v2/hierarchy/${customerName}/${siteId}`,
    )
    .then((response) => response.data);

const loadProjectPlannerStats = (): Promise<ProjectPlannerStat[]> =>
  apiClient
    .get<void, AxiosResponse<ProjectPlannerStat[]>>(`/planner-v2/stats`)
    .then((response) => response.data);

const deferReadyToClose = (
  customerName: string,
  siteId: string,
  actualEventId: string,
): Promise<ProjectPlannerStat> =>
  apiClient
    .post<void, AxiosResponse<ProjectPlannerStat>>(
      `/planner-v2/stats/${customerName}/${siteId}/${actualEventId}/defer`,
    )
    .then((response) => response.data);

export default {
  ...PlannerRepository(apiClient),
  loadSectioningPdfs,
  createSectioningPdf,
  deleteSectioningPdf,
  loadActualEventsReadyToClose,
  loadHierarchyTags,
  loadProjectPlannerStats,
  deferReadyToClose,
};
