import { useCurrentCustomerName, useCurrentSiteId } from "oai-services";
import { useStore } from "vuex";
import { Project } from "@/types/Project";

export const useCurrentProject = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const store = useStore();

  return (store.state.projects as Project[] | undefined)?.find(
    (project) => project.customer_name === customerName && project.site_id === siteId,
  ) as Project;
};

export const useProjects = () => {
  const store = useStore();
  return store.state.projects as Project[];
};
