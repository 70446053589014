<template>
  <MainLayout :activeItem="'Users'">
    <div class="">
      <div class="flex justify-between border-b-2 sticky top-0 z-10 bg-white">
        <h1 class="px-8 py-6 text-3xl font-extrabold text-gray-900">User Overview</h1>
        <div class="py-8 pr-6 gap-x-2 flex">
          <div
            class="hidden md:flex md:items-center"
            v-if="
              hasPermission([
                'pct_tracking_admin_prd_review',
                'pct_tracking_admin_prd_check',
                'pct_tracking_admin_plan',
              ])
            "
          >
            <Menu as="div" class="relative">
              <MenuButton
                type="button"
                class="flex items-center rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
              >
                {{ projectPermission.name }}
                <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-3 w-48 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
                >
                  <div class="py-1 capitalize">
                    <MenuItem
                      v-slot="{ active }"
                      v-for="(permission, index) in projectPermissionGroups"
                      :key="index"
                      @click="projectPermission = permission"
                    >
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >{{ permission.name }}</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div
            class="hidden md:flex md:items-center"
            v-if="
              hasPermission([
                'pct_tracking_admin_prd_review',
                'pct_tracking_admin_prd_check',
                'pct_tracking_admin_plan',
              ])
            "
          >
            <Menu as="div" class="relative">
              <MenuButton
                type="button"
                class="flex items-center rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
              >
                {{ projectStatus }}
                <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
                >
                  <div class="py-1 capitalize">
                    <MenuItem
                      v-slot="{ active }"
                      v-for="(status, index) in projectStatusList"
                      :key="index"
                      @click="(projectStatus = status), loadProjects()"
                    >
                      <a
                        href="#"
                        :class="[
                          active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >{{ status }}</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div v-if="hasPermission(['pct_admin'])">
            <button
              type="button"
              @click="openControllingExportModal = true"
              class="inline-flex items-center rounded-md border border-transparent bg-orange-500 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none"
            >
              <ArrowDownTrayIcon class="h-6 w-6 text-white-400" aria-hidden="true" />
              <div class="pl-4">Export Controlling</div>
            </button>
          </div>
          <div v-if="hasPermission(['pct_invite_base'])">
            <button
              type="button"
              class="inline-flex items-center rounded-md border border-transparent bg-green-400 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none"
              @click="openUserModal = true"
            >
              <UserGroupIcon class="h-6 w-6 text-white-400" aria-hidden="true" />
              <div class="pl-4">Manage Users</div>
            </button>
          </div>
        </div>
      </div>
      <LoadingSection :loading="loading" />
      <div class="overflow-auto bg-white shadow sm:rounded-md" v-if="!loading">
        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="px-8 inline-block min-w-full py-2 align-middle">
              <div class="shadow-sm">
                <table class="min-w-full divide-y divide-gray-300">
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="(project, idx) in projects" :key="project._id">
                      <td class="w-20 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        # {{ idx + 1 }}
                      </td>
                      <td
                        class="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        {{ project.name }}
                      </td>
                      <td class="w-1/6 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ project.customer_name }}_{{ project.site_id }}
                      </td>
                      <td class="w-32 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ project.status }}
                      </td>
                      <td
                        class="flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        <div class="flex">
                          <div
                            v-for="user in projectPermissionMap[
                              `${project.customer_name}#${project.site_id}`
                            ]"
                            :key="user"
                            class="pr-2 items-center"
                          >
                            <span
                              class="inline-flex items-center rounded-full py-0.5 px-2.5 text-sm font-medium"
                              :class="projectPermission.value"
                            >
                              {{ user }}
                              <button
                                v-if="project != currentProject"
                                type="button"
                                class="mx-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full text-red-400 hover:bg-red-200 hover:text-red-500 focus:bg-red-500 focus:text-white focus:outline-none"
                                @click="removeUserProjectPermission(project, user)"
                              >
                                <XMarkIcon class="h-4 w-4 text-red" aria-hidden="true" />
                              </button>
                            </span>
                          </div>
                        </div>
                        <div class="flex">
                          <button
                            v-if="project != currentProject"
                            @click="currentProject = project"
                          >
                            <PlusCircleIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" />
                          </button>
                          <div v-if="project == currentProject" class="flex">
                            <select
                              v-model="selectedUser"
                              id="users"
                              name="users"
                              class="block w-full rounded-md border-gray-300 py-1 pr-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                            >
                              <option disabled selected value>-- select an option --</option>
                              <option v-for="user in userEmails" :key="user">
                                {{ user }}
                              </option>
                            </select>
                            <button @click="addUserProjectPermission(project)" class="pl-2 pt-1">
                              <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                            </button>
                            <button class="pl-1 pt-1" @click="cancelEdit">
                              <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserManagement
        :open="openUserModal"
        :users="users"
        @closeModal="openUserModal = false"
        @addUser="addUser($event)"
        @removeUser="removeUser($event)"
        @updateUser="updateUser($event)"
      ></UserManagement>
    </div>
  </MainLayout>
  <ControllingExport
    :open="openControllingExportModal"
    @close-modal="openControllingExportModal = false"
  ></ControllingExport>
</template>
<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  PlusCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
  UserGroupIcon,
  ArrowDownTrayIcon,
  ChevronDownIcon,
} from "@heroicons/vue/20/solid";
import { defineComponent } from "vue";
import MainLayout from "@/components/layout/MainLayout.vue";
import LoadingSection from "@/components/loading_state/LoadingSection.vue";
import ProjectRepository from "@/repositories/ProjectRepository";
import UserRepository from "@/repositories/UserRepository";
import logger from "@/services/logger";
import { Project, ProjectStatus } from "@/types/Project";
import { User, UserProjectPermissionGroup, UserCompanyType } from "@/types/User";
import ControllingExport from "@/views/user/components/ControllingExport.vue";
import UserManagement from "@/views/user/components/UserManagement.vue";

type ProjectPermissionObject = {
  name: string;
  value: UserProjectPermissionGroup;
};

export default defineComponent({
  name: "UserOverview",
  components: {
    MainLayout,
    UserManagement,
    ControllingExport,
    LoadingSection,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    PlusCircleIcon,
    CheckCircleIcon,
    XMarkIcon,
    UserGroupIcon,
    ArrowDownTrayIcon,
    ChevronDownIcon,
  },
  data() {
    return {
      loading: true as boolean,
      projects: [] as Project[],
      currentProject: null as Project | null,
      projectStatus: "active" as ProjectStatus | null,
      projectPermission: {} as ProjectPermissionObject,
      users: [] as User[],
      selectedUser: "" as string,
      openUserModal: false as boolean,
      openControllingExportModal: false as boolean,
    };
  },
  computed: {
    projectPermissionMap() {
      return this.users.reduce((dict: Record<string, string[]>, item: User) => {
        item.projects.forEach((project) => {
          const key = `${project.customer_name}#${project.site_id}`;
          if (this.projectPermission && project.groups.includes(this.projectPermission.value)) {
            if (key in dict) {
              dict[key].push(item.email);
            } else {
              dict[key] = [item.email];
            }
          }
        });
        return dict;
      }, {});
    },
    userEmails() {
      return this.users
        .map((user) => user.email)
        .filter((item) => {
          if (!this.currentProject) {
            return false;
          }
          const mapping_key = `${this.currentProject.customer_name}#${this.currentProject.site_id}`;
          return (
            !(mapping_key in this.projectPermissionMap) ||
            (mapping_key in this.projectPermissionMap &&
              !this.projectPermissionMap[mapping_key].includes(item))
          );
        });
    },
    projectPermissionGroups() {
      return [
        this.hasPermission(["pct_tracking_admin_prd_review"]) && {
          name: "Process Data Review",
          value: "pct_tracking_prd_review",
        },
        this.hasPermission(["pct_tracking_admin_prd_check"]) && {
          name: "Process Data Check",
          value: "pct_tracking_prd_check",
        },
        this.hasPermission(["pct_tracking_admin_plan"]) && {
          name: "Planner",
          value: "pct_tracking_plan",
        },
      ].filter((mode) => mode) as ProjectPermissionObject[];
    },
    projectStatusList() {
      return ["active", "completed"] as const;
    },
  },
  mounted() {
    this.loadPctUsers();
    if (
      this.hasPermission([
        "pct_tracking_admin_prd_review",
        "pct_tracking_admin_prd_check",
        "pct_tracking_admin_plan",
      ])
    ) {
      if (this.hasPermission(["pct_tracking_admin_prd_review"])) {
        this.projectPermission = {
          name: "Process Data Review",
          value: "pct_tracking_prd_review",
        };
      }
      if (this.hasPermission(["pct_tracking_admin_prd_check"])) {
        this.projectPermission = {
          name: "Process Data Check",
          value: "pct_tracking_prd_check",
        };
      }
      if (this.hasPermission(["pct_tracking_admin_plan"])) {
        this.projectPermission = {
          name: "Planner",
          value: "pct_tracking_plan",
        };
      }
      this.loadProjects();
    }
    this.loading = false;
  },
  methods: {
    loadPctUsers() {
      return UserRepository.loadUsers("pct")
        .then((data) => {
          this.users = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load pct users");
          }
        });
    },
    loadProjects() {
      return ProjectRepository.loadAllProjects(this.projectStatus)
        .then((data) => {
          this.projects = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load projects");
          }
        });
    },
    removeUserProjectPermission(project: Project, userMail: string) {
      const user = this.users.find((user) => user.email === userMail);
      const userProjectPermission = user?.projects.find(
        (p) => p.customer_name === project.customer_name && p.site_id === project.site_id,
      );

      if (!userProjectPermission) {
        return;
      }

      const updatedProjectGroups = (userProjectPermission?.groups || []).filter(
        (group) => group !== this.projectPermission.value,
      );

      if (user && this.projectPermission) {
        return UserRepository.updateUserProjectPermission(
          user.username,
          project.customer_name,
          project.site_id,
          {
            ...userProjectPermission,
            groups:
              updatedProjectGroups.length === 1 && updatedProjectGroups.includes("project_base") // If only base permission left, remove from project
                ? []
                : updatedProjectGroups,
          },
        )
          .then((updatedUser) => {
            if (user) {
              user.projects = updatedUser.projects;
            }
          })
          .catch((error) => {
            if (error?.response?.status !== 404) {
              logger.error(error);
              alert("Unable to set project permission");
            }
          })
          .finally(() => {
            this.cancelEdit();
          });
      }
    },
    addUserProjectPermission(project: Project) {
      const user = this.users.find((user) => user.email === this.selectedUser);
      const userProjectPermission = user?.projects.find(
        (p) => p.customer_name === project.customer_name && p.site_id === project.site_id,
      );

      const updatedProjectGroups = [
        ...(userProjectPermission?.groups || ["project_base"]),
        this.projectPermission.value,
      ];

      const updatedProjectPermission = {
        customer_name: project.customer_name,
        site_id: project.site_id,
        company_type: "other" as UserCompanyType,
        groups: updatedProjectGroups,
      };

      if (user && this.projectPermission) {
        return UserRepository.updateUserProjectPermission(
          user.username,
          project.customer_name,
          project.site_id,
          updatedProjectPermission,
        )
          .then((updatedUser) => {
            if (user) {
              user.projects = updatedUser.projects;
            }
          })
          .catch((error) => {
            if (error?.response?.status !== 404) {
              logger.error(error);
              alert("Unable to set project permission");
            }
          })
          .finally(() => {
            this.cancelEdit();
          });
      }
    },
    cancelEdit() {
      this.currentProject = null;
      this.selectedUser = "";
    },
    addUser(user: User) {
      if (!this.users.some((item) => item._id === user._id)) {
        this.users.push(user);
      }
    },
    removeUser(user: User) {
      this.users = this.users.filter((item) => item._id !== user._id);
    },
    updateUser(userToUpdate: User) {
      this.users = this.users.map((item) => (item._id === userToUpdate._id ? userToUpdate : item));
    },
  },
});
</script>

<style scoped>
.pct_tracking_prd_review {
  @apply bg-green-100 text-green-700;
}

.pct_tracking_prd_check {
  @apply bg-purple-100 text-purple-700;
}

.pct_tracking_plan {
  @apply bg-blue-100 text-blue-700;
}
</style>
