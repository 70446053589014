<template>
  <ModalBasic
    :open="open"
    width="w-2/3"
    maxHeight="95vh"
    @onCloseModal="emit('closeModal'), resetData()"
  >
    <template #title>
      <div class="flex items-center px-2">
        <div class="flex">
          <div class="inline-flex">Section Mask Mapping</div>
        </div>
        <LoadingSpinner v-if="loading" class="ml-2" size="h-6 w-6" />
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-500" />
    </template>
    <template #content-full>
      <div class="pb-4 px-2">
        <label for="location" class="block text-sm font-medium leading-6 text-gray-900"
          >Cameras</label
        >
        <select
          v-model="selectedCameraId"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-600 sm:text-sm sm:leading-6"
        >
          <option default value="all">All cameras</option>
          <option v-for="(cameraId, index) in cameraIds" :key="index" :value="cameraId">
            {{ cameraId }}
          </option>
        </select>
        <label for="location" class="block text-sm font-medium leading-6 text-gray-900 pt-4"
          >Start Date</label
        >
        <div>
          <input
            type="date"
            max="9999-12-12T00:00:00.00"
            class="form-control max-w-lg block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md mr-2"
            v-model="startDate"
          />
        </div>
        <label for="location" class="block text-sm font-medium leading-6 text-gray-900 pt-4"
          >End Date</label
        >
        <div>
          <input
            type="date"
            max="9999-12-12T00:00:00.00"
            class="form-control max-w-lg block shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:max-w-xs sm:text-xs border-gray-300 rounded-md mr-2"
            v-model="endDate"
          />
        </div>

        <div v-if="changesPlanned" class="pt-6 pb-2">
          <div v-for="(changeList, type) in changesPlanned" :key="type">
            <div v-if="changeList.length > 0">
              <div class="font-semibold leading-6 text-gray-900 pt-4">
                {{
                  `${
                    type === "conflictChangesPlanned"
                      ? "Conflict Changes"
                      : type === "sectionChangesPlanned"
                      ? "Section Changes"
                      : "Level to mask mapping not applicable"
                  } (${changeList.length})`
                }}
              </div>
              <div class="-my-2 overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                  <table class="min-w-full divide-y divide-gray-300">
                    <tbody
                      class="divide-y divide-gray-200"
                      :class="[
                        type === 'conflictChangesPlanned'
                          ? 'bg-red-50'
                          : type === 'sectionChangesPlanned'
                          ? 'bg-yellow-100'
                          : 'bg-blue-100',
                      ]"
                    >
                      <tr v-for="change in changeList" :key="change._id">
                        <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                          {{ getDecodedLabel(change.encoded_label as EncodedLabel) }}
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                          {{ change.camera_id }}
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                          {{ formatIsoDate(change.date) }}
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                          {{ change.section_mask_mapping.level_name }}
                        </td>
                        <td class="whitespace-nowrap py-2 pl-4 text-sm text-gray-700 ml-2">
                          {{
                            `${
                              change.section_mask_mapping.curr_section_name
                                ? change.section_mask_mapping.curr_section_name
                                : "[no section]"
                            } → ${
                              change.section_mask_mapping.section_name
                                ? change.section_mask_mapping.section_name
                                : "[no section]"
                            }`
                          }}
                        </td>
                        <td class="w-10 whitespace-nowrap py-2 text-right text-sm">
                          <div>
                            <router-link
                              :to="{
                                name: 'ValidationPrdDate',
                                params: {
                                  date: `${change.date}`,
                                  customer_name: `${route.params.customer_name}`,
                                  site_id: `${route.params.site_id}`,
                                },
                                query: {
                                  camera_id: change.camera_id,
                                  process_id: change._id,
                                },
                              }"
                              class="hover:text-yellow-700 h-full p-2 flex items-center"
                            >
                              <ArrowDownLeftIcon class="h-5 w-5" aria-hidden="true" />
                            </router-link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-8 flex items-center gap-2">
          <button
            type="button"
            class="inline-flex py-2 px-4 items-center rounded-md text-sm font-medium text-white shadow-sm bg-yellow-400 hover:bg-yellow-600"
            :disabled="loading"
            @click="applySectionMaskRemapping()"
          >
            <span>Apply Remapping</span>
          </button>
          <label
            class="flex gap-2 items-center"
            v-if="changesPlanned && changesPlanned.sectionChangesPlanned.length > 0"
          >
            <input
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-yellow-600"
              v-model="applySectionChanges"
            />
            <span>Apply section changes</span>
          </label>
          <label
            class="flex gap-2 items-center"
            v-if="changesPlanned && changesPlanned.conflictChangesPlanned.length > 0"
          >
            <input
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-yellow-600"
              v-model="applyConflictChanges"
            />
            <span>Apply conflict changes</span>
          </label>
        </div>
      </div>
    </template>
  </ModalBasic>
</template>

<script lang="ts" setup>
import { ArrowDownLeftIcon } from "@heroicons/vue/20/solid";
import { format, parseISO } from "date-fns";
import { EncodedLabel, SectionRemappingProcess } from "oai-services";
import { PropType, ref } from "vue";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalBasic from "@/components/modals/ModalBasic.vue";
import { useProcessClasses } from "@/composables/process";
import { useCustomToast } from "@/composables/toast";
import ValidationRepository from "@/repositories/ValidationRepository";
import logger from "@/services/logger";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  cameraIds: {
    type: Object as PropType<string[]>,
    required: true,
  },
});

const emit = defineEmits(["closeModal"]);
const processClasses = useProcessClasses();

const selectedCameraId = ref<string>("all");
const startDate = ref<string>("");
const endDate = ref<string>("");
const route = useRoute();
const changesPlanned = ref<{
  conflictChangesPlanned: SectionRemappingProcess[];
  sectionChangesPlanned: SectionRemappingProcess[];
  levelToMaskMappingNotApplicable: SectionRemappingProcess[];
} | null>(null);
const applyConflictChanges = ref<boolean>(false);
const applySectionChanges = ref<boolean>(false);
const loading = ref<boolean>(false);
const toast = useCustomToast();

const getDecodedLabel = (encodedLabel: EncodedLabel) => {
  return processClasses.value.find((item) => item.encodedLabel === encodedLabel)?.decodedLabel;
};
const formatIsoDate = (isoString: string) => {
  return format(parseISO(isoString), "dd.MM.yyyy");
};

const applySectionMaskRemapping = () => {
  loading.value = true;
  changesPlanned.value = null;

  const { customer_name, site_id } = route.params;
  ValidationRepository.remapOpsReviewProcessesToSectionMasks(
    customer_name as string,
    site_id as string,
    {
      camera_ids: selectedCameraId.value === "all" ? props.cameraIds : [selectedCameraId.value],
      start_date: startDate.value ? startDate.value : null,
      end_date: endDate.value ? endDate.value : null,
    },
    applySectionChanges.value,
    applyConflictChanges.value,
  )
    .then((data) => {
      if (
        data.version_changes.length === 0 &&
        data.level_to_mask_mapping_changes.length === 0 &&
        data.level_to_mask_mapping_not_applicable.length === 0 &&
        data.section_changes_planned.length === 0 &&
        data.conflict_changes_planned.length === 0 &&
        data.section_changes_applied.length === 0 &&
        data.conflict_changes_applied.length === 0
      ) {
        toast.success(`No changes, nothing to update for ${data.no_change_count} processes.`);
      } else {
        const notification = `Updated process versions: ${
          data.version_changes.length
        }\nApplied level-to-mask-changes: ${`${data.level_to_mask_mapping_changes.length}/${
          data.level_to_mask_mapping_changes.length +
          data.level_to_mask_mapping_not_applicable.length
        }`}\nApplied section changes: ${
          data.section_changes_applied.length > 0
            ? `${data.section_changes_applied.length}/${data.section_changes_applied.length}`
            : `0/${data.section_changes_planned.length}`
        }\nApplied conflict changes: ${
          data.conflict_changes_applied.length > 0
            ? `${data.conflict_changes_applied.length}/${data.conflict_changes_applied.length}`
            : `0/${data.conflict_changes_planned.length}`
        }\nProcesses untouched: ${data.no_change_count}`;

        if (
          data.section_changes_planned.length > 0 ||
          data.conflict_changes_planned.length > 0 ||
          data.level_to_mask_mapping_not_applicable.length > 0
        ) {
          changesPlanned.value = {
            conflictChangesPlanned: data.conflict_changes_planned,
            sectionChangesPlanned: data.section_changes_planned,
            levelToMaskMappingNotApplicable: data.level_to_mask_mapping_not_applicable,
          };
          toast.warning(notification);
        } else {
          toast.success(notification);
        }
      }
    })
    .catch((error) => {
      logger.error(error);
      alert("Remapping failed");
    })
    .finally(() => {
      applyConflictChanges.value = false;
      applySectionChanges.value = false;
      loading.value = false;
    });
};

const resetData = () => {
  startDate.value = "";
  endDate.value = "";
  selectedCameraId.value = "all";
  changesPlanned.value = null;
};
</script>
