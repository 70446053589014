<template>
  <!-- @vue-skip fix https://github.com/vuejs/language-tools/issues/3138-->
  <ModalTW :open="open" @onCloseModal="$emit('closed')" customCls="w-full m-5 xl:w-2/3">
    <template #title>Import Plan</template>
    <template #content>
      <div class="flex flex-col gap-7 text-left">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <input
              type="file"
              ref="importPlanInputRef"
              accept="application/xml"
              class="hidden"
              @change="importPlanConfig"
            />
            <div v-if="!loading">
              {{ planConfig?.plan.name || "No plan selected" }}
            </div>
            <LoadingSpinner v-if="loading" size="h-7 w-7" />
            <button
              @click="importPlanInput.click()"
              type="button"
              v-if="!planConfig"
              :disabled="loading"
              class="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              :class="[
                loading
                  ? 'bg-gray-200 hover:bg-gray-200 cursor-default'
                  : 'bg-yellow-500 hover:bg-yellow-600',
              ]"
            >
              Select plan
            </button>
            <button
              @click="clear"
              type="button"
              v-if="planConfig"
              class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            >
              Clear
            </button>
          </div>
          <div
            v-if="changes && originalPlanConfig"
            class="relative"
            :style="overwriteCurrentPlan ? 'filter: grayscale(1)' : ''"
          >
            <ImportPlanChanges
              :changes="changes as Changes"
              :selectedChangeIds="selectedChangeIds"
              @setChange="handleSetChange"
              @setChanges="handleSetChanges"
              style="max-height: 500px; overflow: auto"
              :merges="merges"
              @setMerge="handleSetMerge"
            />
            <div v-if="overwriteCurrentPlan" class="absolute inset-0 bg-white opacity-60" />
          </div>
        </div>
        <div>
          <div class="flex gap-3 items-center">
            <div class="flex items-center gap-2" v-if="planConfig && originalPlanConfig">
              <Switch
                v-model="overwriteCurrentPlan"
                :class="[
                  overwriteCurrentPlan ? 'bg-green-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                ]"
              >
                <span
                  :class="[
                    overwriteCurrentPlan ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  ]"
                >
                  <span
                    :class="[
                      overwriteCurrentPlan
                        ? 'opacity-0 duration-100 ease-out'
                        : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <EllipsisHorizontalIcon class="h-3 w-3 text-gray-400"></EllipsisHorizontalIcon>
                  </span>
                  <span
                    :class="[
                      overwriteCurrentPlan
                        ? 'opacity-100 duration-200 ease-in'
                        : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]"
                    aria-hidden="true"
                  >
                    <CheckIcon class="h-3 w-3 text-green-600"></CheckIcon>
                  </span>
                </span>
              </Switch>
              <span :class="[overwriteCurrentPlan ? 'text-black' : 'text-gray-300']"
                >Overwrite current plan</span
              >
            </div>
            <div class="flex gap-3 flex-1 justify-end">
              <button
                @click="$emit('closed')"
                type="button"
                class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                @click="confirm"
                type="button"
                v-if="planConfig"
                :disabled="!hasSomethingToImport"
                class="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                :class="[
                  hasSomethingToImport
                    ? 'bg-green-400 hover:bg-green-500'
                    : 'bg-gray-200 cursor-default',
                ]"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalTW>
</template>

<script lang="ts">
import { Switch } from "@headlessui/vue";
import { CheckIcon, EllipsisHorizontalIcon } from "@heroicons/vue/24/outline";
import { PlanConfig } from "oai-services";
import { defineComponent, PropType } from "vue";
import { useRoute } from "vue-router";
import LoadingSpinner from "@/components/loading_state/LoadingSpinner.vue";
import ModalTW from "@/components/modals/ModalTW.vue";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";
import ImportPlanChanges from "@/views/planner/components/import/ImportPlanChanges.vue";
import ImportPlanModalMixins, { Changes } from "./ImportPlanMixins";

export default defineComponent({
  name: "ImportPlanModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    originalPlanConfig: {
      type: Object as PropType<PlanConfig>,
      required: false,
    },
  },
  emits: ["closed", "confirmed"],
  components: {
    EllipsisHorizontalIcon,
    CheckIcon,
    ModalTW,
    LoadingSpinner,
    Switch,
    ImportPlanChanges,
  },
  mixins: [ImportPlanModalMixins],
  data: () => ({
    loading: false,
    planConfig: null as PlanConfig | null,
    changes: null as Changes | null,
    overwriteCurrentPlan: false,
    selectedChangeIds: new Set<string>(),
    merges: {} as Record<string, string>,
  }),
  computed: {
    importPlanInput(): HTMLInputElement {
      return this.$refs.importPlanInputRef as HTMLInputElement;
    },
    hasSomethingToImport() {
      return (
        this.overwriteCurrentPlan || !this.originalPlanConfig || this.selectedChangeIds.size > 0
      );
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.clear();
      }
    },
  },
  methods: {
    importPlanConfig(event: Event) {
      const { customer_name, site_id } = this.route.params;
      const inputElement = event.target as HTMLInputElement;
      const file = inputElement.files && inputElement.files.length > 0 && inputElement.files[0];
      if (!file) {
        return;
      }
      this.loading = true;
      PlannerRepository.parseXML(customer_name as string, site_id as string, file)
        .then((planConfig) => {
          this.planConfig = planConfig;
          if (this.originalPlanConfig) {
            this.changes = this.getChanges(
              this.originalPlanConfig,
              this.planConfig as PlanConfig,
              null,
              this.merges,
            );
            this.selectedChangeIds = this.getDefaultChangeIds(this.changes);
          } else {
            this.changes = null;
            this.selectedChangeIds = new Set<string>();
          }
        })
        .catch((error) => {
          logger.error(error);
          alert("Import failed");
        })
        .finally(() => {
          this.importPlanInput.value = "";
          this.loading = false;
        });
    },
    confirm() {
      if (!this.planConfig) {
        return;
      }
      const mergedPlanConfig =
        this.overwriteCurrentPlan || !this.originalPlanConfig
          ? this.planConfig
          : this.getMergedPlanConfig(
              this.originalPlanConfig,
              this.planConfig,
              this.changes as Changes,
              this.selectedChangeIds,
              this.merges,
            );

      const mergedPlannerItems = [];
      for (const [key, value] of Object.entries(this.merges)) {
        const addedItem = this.changes?.addedPlannerItems.find((item) => item._id === key);
        const originalItem = this.originalPlanConfig?.planner_items.find(
          (item) => item._id === value,
        );
        mergedPlannerItems.push({ from: originalItem, to: addedItem?.plannerItem });
      }

      this.$emit("confirmed", {
        planConfig: mergedPlanConfig,
        mergedPlannerItems: mergedPlannerItems,
      });
    },
    clear() {
      this.loading = false;
      this.planConfig = null;
      this.changes = null;
      this.overwriteCurrentPlan = false;
      this.selectedChangeIds = new Set();
      this.merges = {};
    },
    recalculateChanges() {
      if (this.originalPlanConfig && this.planConfig) {
        this.changes = this.getChanges(
          this.originalPlanConfig,
          this.planConfig,
          this.selectedChangeIds,
          this.merges,
        );
        this.selectedChangeIds = this.getOnlyValidChangeIds(this.changes, this.selectedChangeIds);
        this.merges = this.getOnlyValidMerges(this.selectedChangeIds, this.merges);
      }
    },
    handleSetChange({ changeId, selected }: { changeId: string; selected: boolean }) {
      if (selected) {
        this.selectedChangeIds.add(changeId);
      } else {
        this.selectedChangeIds.delete(changeId);
      }
      this.recalculateChanges();
    },
    handleSetChanges(changeIds: { changeId: string; selected: boolean }[]) {
      changeIds.forEach(({ changeId, selected }) => {
        if (selected) {
          this.selectedChangeIds.add(changeId);
        } else {
          this.selectedChangeIds.delete(changeId);
        }
      });
      this.recalculateChanges();
    },
    getOnlyValidChangeIds(changes: Changes, selectedChangeIds: Set<string>) {
      const allChanges = new Set<string>(
        [
          ...(changes.updatedPlan ? [{ _id: changes.updatedPlan._id }] : []),
          ...changes.updatedPlannedEvents,
          ...changes.updatedPlannerItems,
          ...changes.addedPlannerItems,
          ...changes.deletedPlannerItems.filter((change) => !change.hidden),
          ...changes.updatedParentPlannerItems,
          ...changes.connectedPlannedEvents,
          ...changes.disconnectedPlannedEvents,
        ].map((change) => change._id),
      );
      return new Set<string>([...selectedChangeIds].filter((changeId) => allChanges.has(changeId)));
    },
    getOnlyValidMerges(selectedChangeIds: Set<string>, merges: Record<string, string>) {
      return Object.entries(merges).reduce((acc, [changeId, plannerItemId]) => {
        if (selectedChangeIds.has(changeId)) {
          acc[changeId] = plannerItemId;
        }
        return acc;
      }, {} as Record<string, string>);
    },
    getDefaultChangeIds(changes: Changes) {
      return new Set<string>(
        [
          ...(changes.updatedPlan ? [{ _id: changes.updatedPlan._id }] : []),
          ...changes.updatedPlannedEvents,
          ...changes.updatedPlannerItems,
          ...changes.addedPlannerItems,
          ...changes.updatedParentPlannerItems,
          ...changes.connectedPlannedEvents,
          ...changes.disconnectedPlannedEvents,
        ].map((change) => change._id),
      );
    },
    handleSetMerge(merge: { changeId: string; to: string }) {
      this.merges[merge.changeId] = merge.to;
      this.recalculateChanges();
    },
  },
  setup() {
    const route = useRoute();
    return { route };
  },
});
</script>
