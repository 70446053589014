import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { useCustomToast } from "@/composables/toast";
import HierarchyRepository from "@/repositories/HierarchyRepository";
import PlannerRepository from "@/repositories/PlannerRepository";
import logger from "@/services/logger";
import { HierarchyTagStore } from "@/types/HierarchyTag";

export const useAllHierarchyTags = () => {
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags-all"],
    queryFn: () => HierarchyRepository.loadAllHierarchyData(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load all hierarchy tags");
      return false;
    },
  });

  return { hierarchyTags: data, areHierarchyTagsLoading: isLoading };
};

export const useHierarchyTags = (customerName: string, siteId: string) => {
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags", customerName, siteId],
    queryFn: () => PlannerRepository.loadHierarchyTags(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const hierarchyTags = computed(
    () => data.value?.slice().sort((a, b) => a.number - b.number) || [],
  );

  return { hierarchyTags, isLoading };
};
